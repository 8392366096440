import { Image, View } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import { useEffect } from "react";
import { Container } from 'react-bootstrap';
import { Outlet, useNavigate } from "react-router-dom";

export const getUserById = /* GraphQL */ `
query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      title
      company
      phone
      email
	    image
	    role
      active
      shipperGroups {
		   	items {
			  	id
				  role
				  shipperGroup {
					 id
					 name
            timezone {
              id
              alias
              name
            }
				  }
			  }
	    }
      shippers {
        items {
          role
		      shipper {
            id
            name
            shipperGroupId
            address {
              location {
                latitude
                longitude
              }
            }
            timezone {
              id
              alias
              name
            }
		      }
        }
      }
    }
}`;

export const getInviteUser = /* GraphQL */ `
  query GetInviteUser($id: ID!) {
    getInviteUser(id: $id) {
      id
      phone
      email
      role
      active
      createdAt
      updatedAt
    }
  }
`;

export const createUserMutation = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
    }
  }
`;

export const createShipperUserMutation = /* GraphQL */ `
  mutation CreateShipperUser($input: CreateShipperUserInput!) {
    createShipperUser(input: $input) {
      id
    }
  }
`;

export const createCarrierUserMutation = /* GraphQL */ `
  mutation CreateCarrierUser($input: CreateCarrierUserInput!) {
    createCarrierUser(input: $input) {
      id
    }
  }
`;

export const updateInviteUser = /* GraphQL */ `
  mutation UpdateInviteUser($input: UpdateInviteUserInput!) {
    updateInviteUser(input: $input) {
      id
    }
  }
`;

export const Vocabulary = () => {
  I18n.putVocabulariesForLanguage('en', {

  });
}

const imagePaths = {
  'Sign In': '/img/login-cover-new.png',
  'Forgot Password': '/img/forget.png',
  'Reset Password': '/img/forget.png',
  'Confirm SignIn': '/img/forget.png',
  'Sign Up': '/img/forget.png',
};

export const CommonHeader = ({ title, subtitle, setImage }) => {
  useEffect(() => { setImage && setImage(imagePaths[title] || '/img/login-cover-new.png') }, [setImage, title]);
  return (
    <></>
  )
};


export const AuthLayout = () => {

  const navigate = useNavigate()

  useEffect(() => {
    if (window.top !== window.self) {
      navigate("/unauthorized")
    }
  }, []);

  return (
    <section className="main-card-container d-flex align-items-center">
      <Container fluid className="login-container">
        <Outlet />
      </Container>
    </section>
  );
};

export default AuthLayout;

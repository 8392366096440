
import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Col, Dropdown } from 'react-bootstrap';

export const UserCarrierFilter = ({ value, onChange, carrierList, dark }) => {
    const name = value?.name ?? 'All';
    const color = dark ? 'btn-dark' : 'btn-light'
    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Courier: {name}
                </button>
                <div className={`dropdown-menu dropdown-menu-right}`} aria-labelledby='dropdownMenuButton'  >
                    <div>
                        {carrierList.map((item, index) =>
                            <Dropdown.Item key={index} className='px-3' onClick={() => onChange(item)}>
                                {item?.name}
                            </Dropdown.Item>)}
                    </div>
                    <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>All</button>
                </div>
            </Dropdown>

        </Col>
    )
}
export const ServiceTypeFilter = ({ value, onChange, serviceList, carrierId }) => {

    let newServiceList = serviceList.filter((item) => item.carrierId === carrierId) || serviceList
    const name = value?.name ?? 'All';
    return (
        <Col className='col-auto px-1 my-1 col'>
            <Dropdown>
                <Dropdown.Toggle size='sm' variant='light' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Service: {name}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {
                        newServiceList.map((item, index) =>
                            <Dropdown.Item key={index} className='px-3' onClick={() => onChange(item)}>
                                {item?.name}
                            </Dropdown.Item>
                        )
                    }
                    <hr className='my-1' />
                    <Dropdown.Item className='px-3' onClick={() => onChange()}>
                        All
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Col>
    )
}


export const DriverFilter = ({ value, onChange, shipper, carrier }) => {
    const [driverList, setDriverList] = useState([]);
    const search = async (keyword) => {
        const apiName = "api";
        const path = `${`/search/user?size=1000`}`;
        const init = {
            body: {
                sort: {
                    "name.keyword": {
                        "order": "asc"
                    }
                },
                query: {
                    bool: {
                        "filter": [
                            {
                                "term": {
                                    "carriers.carrier.name.keyword": carrier?.name
                                }
                            }
                        ]
                    }
                }
            },
        };
        if (keyword) init.body.query.bool.must.push({
            "multi_match": {
                "query": keyword,
                "type": "phrase_prefix",
                "fields": ["name", "alias"]
            }
        })
        const { hits } = await API.post(apiName, path, init);
        let sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];
        sourceData?.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'accent' }))
        let items = [{
            name: "Unassigned",
            id: "driverNotAssigned",
        }]
        sourceData.map((item) => (
            items.push(
                {
                    name: item.name,
                    id: item.id,
                })
        ));
        setDriverList(items)
    }

    useEffect(() => {
        search()
        onChange(null);
    }, [shipper, carrier])


    return (
        <Col className='col-auto px-1 my-1 col'>
            <Dropdown>
                <Dropdown.Toggle size='sm' variant='light' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Driver: {value?.name || "All"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {
                        driverList.map((item, index) => {
                            return <Dropdown.Item key={index} className='px-3' onClick={() => onChange(item)}>
                                {item?.name}
                            </Dropdown.Item>
                        })
                    }
                    <hr className='my-1' />
                    <Dropdown.Item className='px-3' onClick={() => onChange()}>
                        All
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Col>
    )
}

export const LogTypeFilter = ({ value, dark, onChange}) => {
 const name = value?.name ?? 'All';
 const color = dark ? 'btn-dark' : 'btn-light'

    const statusOptions = [
        { name: 'SMS', value: 'SMS' },
        { name: 'Email', value: 'EMAIL' },
        { name: 'Call', value: 'CALL' },
    ]

    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Log Type: {name}
                </button>
                <div className={`dropdown-menu dropdown-menu-right}`} aria-labelledby='dropdownMenuButton'  >
                    <div>
                        {statusOptions.map((item, index) =>
                            <Dropdown.Item key={index} className='px-3' onClick={() => onChange(item)}>
                                {item?.name}
                            </Dropdown.Item>)}
                    </div>
                    <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>All</button>
                </div>
            </Dropdown>

        </Col>
    )
}




export const CommunicationFilter = ({ value, onChange, dark }) => {
    const name = value?.name ?? 'All';
    const color = dark ? 'btn-dark' : 'btn-light'

    let statusOptions = [
        { name: 'Delivered', value: 'DELIVERED' },
        { name: 'Not Delivered', value: 'NOT_DELIVERED' },
        { name: 'Pending', value: 'PENDING' },
        
    ]
    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Status: {name}
                </button>
                <div className={`dropdown-menu dropdown-menu-right}`} aria-labelledby='dropdownMenuButton'  >
                    <div>
                        {statusOptions.map((item, index) =>
                            <Dropdown.Item key={index} className='px-3' onClick={() => onChange(item)}>
                                {item?.name}
                            </Dropdown.Item>)}
                    </div>
                    <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>All</button>
                </div>
            </Dropdown>

        </Col>
    )
}
import { API } from 'aws-amplify';
import axios from 'axios';
import moment from 'moment-timezone';
import phone from 'phone';
import { toast } from 'react-hot-toast';

export const PAGE_TITLE = "| Phox Health";
export const PAGE_SIZE = 100;
export const DELAY_TIMEOUT = 700;
const DEFAULT_TIMEZONE = "America/New_York";

export const isOwner = (role) => role === "OWNER";
export const isEditor = (role) => role === "EDITOR";
export const isViewer = (role) => role === "VIEWER";

export const isValidPhone = (number) => {
  if (number) {
    if (number === "+1" || number === "+") {
      return true;
    } else {
      return phone(number, { country: "USA" }).isValid;
    }
  } else {
    return true;
  }
};

export const getTZ = async (shipperGroupId, shipperId, carrier) => {
  if (shipperGroupId) {
    const getShipperGroup = /* GraphQL */ `
      query GetShipperGroup($id: ID!) {
        getShipperGroup(id: $id) {
          id
          timezone {
            alias
            id
            name
          }
        }
      }
    `;
    try {
      let res = await API.graphql({
        query: getShipperGroup,
        variables: { id: shipperGroupId },
      });
      return res?.data?.getShipperGroup?.timezone;
    } catch (error) {
      console.error(error);
    }
  } else if (shipperId) {
    const getShipper = /* GraphQL */ `
      query GetShipper($id: ID!) {
        getShipper(id: $id) {
          id
          timezone {
            alias
            id
            name
          }
        }
      }
    `;
    try {
      let res = await API.graphql({
        query: getShipper,
        variables: { id: shipperId },
      });
      return res?.data?.getShipper?.timezone;
    } catch (error) {
      console.error(error);
    }
  } else {
    const getCarrier = /* GraphQL */ `
      query GetCarrier($id: ID!) {
        getCarrier(id: $id) {
          id
          timezone {
            alias
            id
            name
          }
        }
      }
    `;
    try {
      let res = await API.graphql({
        query: getCarrier,
        variables: { id: carrier },
      });
      return res?.data?.getCarrier?.timezone;
    } catch (error) {
      console.error(error);
    }
  }
};

export const isValidEmail = (email) => {
  if (email) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return email.match(regex);
  } else {
    return true;
  }
};



export const handleApiError = (error) => {
  toast.error(
    "Oops! Looks like there was an error trying to process your request. Please try again."
  );
  console.error(error);
};

export const toExportDateTime = (timestamp) => {
  return timestamp ? moment.unix(timestamp).format("D-MMM-YYYY") : "-";
};

export const toExportDate = (timestamp) => {
  return timestamp ? moment.unix(timestamp).format("D-MMM-YYYY") : "-";
};

export const toLocalDateTime = (
  timestamp,
  timezone = DEFAULT_TIMEZONE,
  inTracker = false
) => {
  return inTracker
    ? timestamp
      ? `${toLocalDate(timestamp, timezone)} at ${toLocalTime(
        timestamp,
        timezone
      )}`
      : "-"
    : timestamp
      ? `${toLocalDate(timestamp, timezone)} ${toLocalTime(timestamp, timezone)}`
      : "-";
};

export const toLocalTime = (timestamp, timezone = DEFAULT_TIMEZONE) => {
  return timestamp
    ? moment.unix(timestamp).tz(timezone).format("h:mm a z")
    : "-";
};

export const toLocalDate = (timestamp, timezone = DEFAULT_TIMEZONE) => {
  return timestamp
    ? moment.unix(timestamp).tz(timezone).format("MMM D, YYYY")
    : "-";
};

export const getStatusBadgeColor = (status) => {
  let color = "secondary";
  if (status === "delivered") color = "success";
  else if (status === "pending") color = "primary-soft";
  else if (status === "undelivered") color = "danger";

  return `${color}`;
};;

export const getBadgeIcons = (selectedLogType, data) => {
  if (selectedLogType == "SMS") {
    return <i className={`fe fe-message-square`}></i>;
  } else if (selectedLogType == "EMAIL") {
    return <i className={`fe fe-mail`}></i>;
  } else if (selectedLogType == "CALL") {
    return <i className={`fe fe-phone`}></i>;
  }
};

export const getBadgeStatus = (selectedLogType, data) => {
  if (selectedLogType == "SMS") {
    return data.SmsStatus?.charAt(0).toUpperCase() + data.SmsStatus?.slice(1);
  } else if (selectedLogType == "EMAIL") {
    return data.event?.charAt(0).toUpperCase() + data.event?.slice(1);
  } else if (selectedLogType == "CALL") {
    // TODO : Add logic for Call details
  }
};

export const getIconBadgeColor = (status, isSideBar = false) => {

  let color = "secondary";

  if (status === "SMS") {
    color = isSideBar ? "secondary" : "warning-soft";
  } else if (status === "EMAIL") {
    color = isSideBar ? "secondary" : "primary-soft";
  } else if (status === "CALL") {
    color = isSideBar ? "secondary" : "success-soft";
  }

  return color;
};

export const statusIcon = (status) => {
  let icon = "";

  if (status === "OPEN") icon = "box";
  if (status === "READY_FOR_PICKUP") icon = "package";
  else if (status === "IN_TRANSIT") icon = "truck";
  else if (status === "OUT_FOR_DELIVERY") icon = "truck";
  else if (status === "DELIVERED") icon = "check-circle";
  else if (status === "EXCEPTION") icon = "alert-triangle";
  else if (status === "OUT_FOR_RETURN") icon = "arrow-left-circle";
  else if (status === "RETURNED") icon = "check-circle";

  return `fe fe-${icon}`;
};

export const boxStatusIcon = (status) => {
  let icon = "";
  if (status === "IN_USE") icon = "truck";
  else if (status === "DAMAGE") icon = "alert-triangle";
  else if (status === "RETIERD") icon = "alert-triangle";
  else if (status === "OUT_FOR_RETURN") icon = "arrow-left-circle";
  else if (status === "RETURNED") icon = "check-circle";

  return `fe fe-${icon}`;
};

export const statusButtonColor = (status) => {
  let color = "primary-soft";

  if (status === "OPEN") color = "light";
  else if (status === "READY_FOR_PICKUP") color = "primary-soft";
  else if (status === "IN_TRANSIT") color = "warning-soft";
  else if (status === "OUT_FOR_DELIVERY") color = "warning";
  else if (status === "DELIVERED") color = "success";
  else if (status === "EXCEPTION") color = "danger";
  else if (status === "OUT_FOR_RETURN") color = "purple-soft";
  else if (status === "RETURNED") color = "purple";

  return `btn btn-${color}`;
};

export const statusBadgeColor = (status) => {
  let color = "primary-soft";

  if (status === "OPEN") color = "light";
  if (status === "READY_FOR_PICKUP") color = "primary-soft";
  else if (status === "IN_TRANSIT") color = "warning-soft";
  else if (status === "OUT_FOR_DELIVERY") color = "warning";
  else if (status === "DELIVERED") color = "success";
  else if (status === "EXCEPTION") color = "danger";
  else if (status === "OUT_FOR_RETURN") color = "purple-soft";
  else if (status === "RETURNED") color = "purple";

  return `${color}`;
};
export const boxstatusBadgeColor = (status) => {
  let color = "primary-soft";

  if (status === "AVAILABLE") color = "success";
  else if (status === "IN_USE") color = "warning-soft";
  else if (status === "OUT_FOR_RETURN") color = "purple-soft";
  else if (status === "RETURNED") color = "purple";
  else if (status === "DAMAGED") color = "danger";
  else if (status === "RETIRED") color = "danger";

  return `${color}`;
};

export const BoxstatusButtonColor = (status) => {
  let color = "primary-soft";

  if (status === "READY_FOR_PICKUP") color = "primary-soft";
  else if (status === "AVAILABLE") color = "success";
  else if (status === "IN_USE") color = "warning-soft";
  else if (status === "OUT_FOR_RETURN") color = "purple-soft";
  else if (status === "RETURNED") color = "purple";
  else if (status === "DAMAGED") color = "danger";
  else if (status === "RETIRED") color = "danger";

  return `btn btn-${color}`;
};

export const placesAutoCompleteOptions = {
  componentRestrictions: { country: ["us"] },
};

export const getLocationTimezone = async (location) => {
  if (location && location.latitude && location.longitude) {
    const timestamp = 0;
    let url = "https://maps.googleapis.com/maps/api/timezone/json?";
    url = `${url}&location=${location.latitude},${location.longitude}`;
    url = `${url}&timestamp=${timestamp}`;
    url = `${url}&key=${process.env.REACT_APP_GOOLGE_API_KEY}`;

    try {
      const { data } = await axios.get(url);
      return {
        id: data.timeZoneId,
        name: data.timeZoneName,
        alias: data.timeZoneName.match(/\b(\w)/g)?.join(""),
      };
    } catch (error) {
      console.error(error);
    }
  }
};

// fun for TitleCase by Manish
export function toTitleCase(str) {
  return str?.replace(/\w\S*/g, function (txt) {
    return txt?.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const FormError = ({ error }) => {
  return (
    <>{error && <div className="text-danger mt-2 ms-1 h5">{error}</div>}</>
  );
};

export const FieldLabel = ({ text }) =>
  text && (
    <label className="form-label">
      {text}
      <span className="text-danger">*</span>
    </label>
  );

export function getDateTimeByTimezone(
  timestamp,
  timezone,
  format = "DD-MM-YYYY hh:mm A"
) {
  const date = moment(timestamp * 1000).tz(timezone);
  const formattedDate = date.format(format);
  return formattedDate;
}

// get distance

export const getDistance = async (origin, destination) => {
  if (
    origin &&
    origin.latitude &&
    origin.longitude &&
    destination &&
    destination.latitude &&
    destination.longitude
  ) {
    try {
      return new Promise((resolve) => {
        const matrix = new window.google.maps.DistanceMatrixService();
        matrix.getDistanceMatrix(
          {
            origins: [
              new window.google.maps.LatLng(origin.latitude, origin.longitude),
            ],
            destinations: [
              new window.google.maps.LatLng(
                destination.latitude,
                destination.longitude
              ),
            ],
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          function (response) {
            const factor = 0.621371;
            const miles =
              (response &&
                response.rows.length &&
                response.rows[0] &&
                response.rows[0].elements.length &&
                response.rows[0].elements[0] &&
                response.rows[0].elements[0].distance &&
                response.rows[0].elements[0].distance.text
                  .split(" ")[0]
                  .replace(/,/g, "")) * factor || 0;
            resolve(Math.round(miles));
          }
        );
      });
    } catch (error) {
      console.error(error);
    }
  } else {
    toast.error("Invalid origin or destination");
  }
};

// get distance from here
export const getDistanceFromHere = async (origin, destination) => {
  if (
    origin &&
    origin.latitude &&
    origin.longitude &&
    destination &&
    destination.latitude &&
    destination.longitude
  ) {
    try {
      let apiUrl = "https://router.hereapi.com/v8/routes?";
      apiUrl = `${apiUrl}transportMode=car`;
      apiUrl = `${apiUrl}&units=metric`;
      apiUrl = `${apiUrl}&return=summary`;
      apiUrl = `${apiUrl}&avoid[features]=ferry`;
      apiUrl = `${apiUrl}&origin=${origin.latitude},${origin.longitude}`;
      apiUrl = `${apiUrl}&destination=${destination.latitude},${destination.longitude}`;
      apiUrl = `${apiUrl}&apiKey=${process.env.REACT_APP_HERE_API_KEY}`;

      const response = await axios.get(apiUrl);
      const distance = response.data.routes[0].sections[0].summary.length;
      return Math.round(distance * 0.000621371);
    } catch (error) {
      console.error("getDistance", error);
    }
  } else {
    console.error(getDistance, "Invalid origin or destination");
  }
};

//format Phone Number In US/Indian  format

export const formatNumber = (phoneNumber) => {
  if (!phoneNumber || phoneNumber.length <= 10) return phoneNumber;
  let formattedNumber = phoneNumber.replace(/[^\d]/g, "");

  if (formattedNumber.startsWith("1")) {
    formattedNumber = formattedNumber.slice(1);
  } else if (formattedNumber.startsWith("91")) {
    formattedNumber = formattedNumber.slice(2);
  }

  formattedNumber = formattedNumber.replace(
    /(\d{3})(\d{3})(\d{4})/,
    "($1) $2-$3"
  );
  return formattedNumber;
};

import React from 'react';
import { Bar, Doughnut, Line } from 'react-chartjs-2';

export const BarChart = ({ data, width, height }) => {

	const chartData = {
		labels: data?.labels,
		datasets: [{
			data: data?.datasets,
			backgroundColor: '#2c7be5',
			maxBarThickness: 10,
			borderRadius: 12,
		}]
	};

	const chartOptions = {
		maintainAspectRatio: false,
		animation: {
			delay: (context) => {
				let delay = 0;
				if (context.type === 'data' && context.mode === 'default') {
					delay = context.dataIndex * 70 + context.datasetIndex * 10;
				}
				return delay;
			},
		},
		plugins: {
			title: { display: false },
			legend: { display: false }
		},
		scales: {
			x: {
				stacked: true,
				grid: {
					display: false,
				}
			},
			y: {
				ticks: {
					borderDash: [5, 5],
					precision: 0
				},
				stacked: true
			}
		},
	};

	return <Bar type='bar' data={chartData} options={chartOptions} width={width | 400} height={height | 300} />
};

export const DoughnutChart = ({ data, width, height, backgroundColor, hideLabel, centerText }) => {
	const chartData = {
		labels: data?.labels,
		datasets: [{
			data: data?.datasets,
			backgroundColor: backgroundColor ? backgroundColor : ['#2C7BE5', '#A6C5F7', '#D2DDEC', '#f6c343', '#fdf3d9', '#fad7dd', '#d7eff6'],
			hoverBackgroundColor: backgroundColor ? backgroundColor : ['#2C7BE5', '#A6C5F7', '#D2DDEC', '#f6c343', '#fdf3d9', '#fad7dd', '#d7eff6'],
		}]
	};

	const chartOptions = {
		maintainAspectRatio: false,
		cutout: '85%',
		plugins: {
			title: { display: false },
			legend: {
				display: hideLabel ? false : true,
				position: 'bottom',
				padding: { top: 100 },
				title: {
					fontColor: 'black',
					fontSize: 12,
				},
				labels: {
					pointStyle: 'circle',
					usePointStyle: true,
				}
			},
			tooltip: {
				enabled: true,
			},
		}
	};

	const centerTextPlugin = {
		id: 'centerTextPlugin',
		beforeDraw: (chart) => {
			const { width, height, ctx } = chart;
			ctx.restore();

			const fontSize = (height / 114).toFixed(2);

			const text = centerText?.text || '';
			ctx.font = `${fontSize}em sans-serif`;
			ctx.textBaseline = 'middle';
			ctx.fillStyle = '#333';
			const textX = Math.round((width - ctx.measureText(text).width) / 2);
			const textY = height / 2.2;
			ctx.fillText(text, textX, textY);

			const subText = centerText?.subText || '';
			if (subText) {
				ctx.font = `${(fontSize * 0.6).toFixed(2)}em sans-serif`;
				ctx.fillStyle = 'grey';
				const subTextX = Math.round((width - ctx.measureText(subText).width) / 2);
				const subTextY = height / 1.7;
				ctx.fillText(subText, subTextX, subTextY);
			}
		},
	};

	return <Doughnut plugins={[centerTextPlugin]} data={chartData} options={chartOptions} width={width || 400} height={height || 300} />
}

export const LineChart = ({ data, width, height, min, max }) => {

	const chartData = {
		labels: data?.labels,
		datasets: [
			{
				data: data?.datasets,
				fill: false,
				backgroundColor: '#2c7be5',
				borderColor: '#2c7be5',
				tension: 0.4,
			}
		]
	}

	const chartOptions = {
		maintainAspectRatio: false,
		scales: {
			x: {
				grid: { display: false }
			}
		},
		plugins: {
			title: { display: false },
			legend: { display: false },
		},
	};

	if (min && max) {
		chartOptions.scales.y = chartOptions.scales.y || {};
		chartOptions.scales.y.ticks = { callback: (value) => Number.isInteger(value) ? value : '' };
		chartOptions.scales.y.suggestedMin = min;
		chartOptions.scales.y.suggestedMax = max;
	}

	return <Line data={chartData} options={chartOptions} width={width || 400} height={height || 300} />
}

export const StackedBarChart = ({ data, width, height, dark }) => {

	const chartData = {
		labels: data?.labels,
		datasets: [{
			data: data?.datasets,
			backgroundColor: '#2c7be5',
			maxBarThickness: 10,
			borderRadius: 12,
		}]
	}

	let chartOptions = {
		maintainAspectRatio: false,
		animation: {
			delay: (context) => {
				let delay = 0;
				if (context.type === 'data' && context.mode === 'default') {
					delay = context.dataIndex * 70 + context.datasetIndex * 10;
				}
				return delay;
			},
		},
		plugins: {
			title: { display: false },
			legend: { display: false },
		},
		scale: {
			scaleLabel: {
				fontColor: 'red'
			}
		},
		scales: {
			x: {
				stacked: true,
				grid: { display: false, },
				ticks: {}
			},
			y: {
				stacked: true,
				grid: {},
				ticks: {
					borderDash: [5, 5],
					precision: 0,
				},
			},
		},
	};

	if (dark) {
		chartOptions.scales.x.ticks.color = '#95aac9';
		chartOptions.scales.x.grid.color = '#283E59';
		chartOptions.scales.y.ticks.color = '#95aac9';
		chartOptions.scales.y.grid.color = '#283E59';

	}

	return <Bar data={chartData} options={chartOptions} width={width || 400} height={height || 300} />
};

import jsPDF from 'jspdf';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { handleApiError, toLocalDate, toLocalTime } from '../../helpers';

const DownloadPOD = ({ shipment, multi_Dimensional_Shipment_Item, downloadPodPdf, setDownloadPodPdf }) => {
    const [history, setHistory] = useState(null);
    const [imageArray, setImageArray] = useState()
    const [logo, setLogo] = useState('')

    useEffect(() => {
        const index = shipment?.history?.findIndex(
            (x) => x.status === "DELIVERED" || 'RETURNED'
        );
        if (index >= 0) {
            let historyItem = shipment?.history[index];
            if (historyItem.signature && typeof historyItem.signature === "string")
                historyItem.signature = JSON.parse(historyItem.signature);
            setHistory(historyItem);
        }
    }, [shipment?.history]);

    let closedByOtp = false;
    const otp = shipment?.history?.find(item => item?.description?.toLowerCase() === "closed by pin");
    if (otp) { closedByOtp = true; }

    let actualDeliveryLocation = null;
    shipment?.history?.map((history) => { history.status === 'DELIVERED' && (actualDeliveryLocation = history?.location) });

    useEffect(() => {
        if (shipment?.attachment?.length > 0) { convertImagesToBase64(shipment.attachment) }
    }, [shipment?.attachment]);

    useEffect(() => {
        const GetLogobase64 = async () => {
            const phoxOSLogo = await base64Img('/img/PhoxOS-logo.png')
            setLogo(phoxOSLogo)
        }
        GetLogobase64()
    }, [])

    const convertImagesToBase64 = async (urls) => {
        try {
            const base64Array = await Promise.all(urls.map((url) => base64Img(url)));
            setImageArray(base64Array);
        } catch (error) {
            handleApiError(error)
        }
    };

    const base64Img = (url) => {
        return new Promise((resolve, reject) => {
            fetch(url)
                .then((response) => response.blob())
                .then((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(blob);
                })
                .catch((error) => {
                    handleApiError(error)
                    reject(error);
                });
        });
    };

    // PDF function
    let pdf = new jsPDF('p', 'pt', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight() - 20;

    useEffect(() => {
        setTimeout(() => {
            if (downloadPodPdf) generatePdf();
        }, 700);
    }, [downloadPodPdf]);

    const generatePdf = async () => {
        let html = document.getElementById(`shipmentPOD`);

        if (html) {
            html.classList.remove('d-none');
            pdf.html(html, {
                callback: function (pdf) {
                    pdf.save(`shipment#${shipment?.number}.pdf`);
                    setDownloadPodPdf(false);
                },
                autoPaging: 'text',
            });
            setTimeout(() => html.classList.add('d-none'), 100);
        } else {
            toast.error('Something went wrong');
        }
    };

    return (
        <div
            id="shipmentPOD"
            className="p-4 d-none"
            style={{
                width: `${pdfWidth}px`,
                minHeight: `${pdfHeight - 100}px`,
                fontFamily: 'Arial, sans-serif',
                fontSize: '10px',
                lineHeight: '1.4',
            }}>

            <div className='border border-dark p-3'>
                <div style={{ minHeight: `${pdfHeight - 80}px`, }}>
                    <Row className="border-bottom p-2 border-dark">
                        <Col xs={4}>
                            <div className="googlemap rounded border-dark">
                                {actualDeliveryLocation ? (<img src={`https://maps.googleapis.com/maps/api/staticmap?size=160x160&maptype=roadmap&markers=color:green%7C${actualDeliveryLocation?.latitude},${actualDeliveryLocation?.longitude}&markers=color:red%7C${shipment?.shipTo?.address?.location?.latitude},${shipment?.shipTo?.address?.location?.longitude}&format=png&style=feature:poi|element:labels|visibility:off&key=${process.env.REACT_APP_GOOLGE_API_KEY}`} alt="Phox-Map" />) :
                                    (<img src={`https://maps.googleapis.com/maps/api/staticmap?zoom=16&size=160x160&maptype=roadmap%20&markers=color:green%7C${shipment?.shipTo?.address?.location?.latitude},${shipment?.shipTo?.address?.location?.longitude}&format=png&style=feature:poi|element:labels|visibility:off&key=${process.env.REACT_APP_GOOLGE_API_KEY}`} alt="Phox-Map" />)}
                            </div>
                        </Col>
                        <Col xs={8} className="mt-3">
                            <Row className='border-bottom'>
                                <Col>
                                    <h2 className='mb-2'>#{shipment?.number}</h2>
                                    <h5>{shipment?.extId}</h5>
                                </Col>
                                <Col>
                                    <div>
                                        <div className="fw-bold">{shipment?.shipTo?.name || "-"}</div>
                                        <div>{shipment?.shipTo?.address?.address1}</div>
                                        <div>{shipment?.shipTo?.address?.address2}</div>
                                        <div>{`${shipment?.shipTo?.address?.city}, ${shipment?.shipTo?.address?.state} ${shipment?.shipTo?.address?.postalCode}`}</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <div className='h3 mt-3 mb-2'>
                                        <span className={`badge bg-success`}>Delivered</span>
                                    </div>
                                    <p>
                                        {`${toLocalTime(shipment?.actualDeliveryTime, shipment?.shipFrom?.timezone?.id)}, ${toLocalDate(
                                            shipment?.actualDeliveryTime,
                                            shipment?.shipFrom?.timezone?.id,
                                            shipment?.shipFrom?.timezone?.alias
                                        )}`}
                                    </p>
                                </Col>
                                <Col>
                                    <p className="text-muted mt-3 mb-3">Delivered By</p>
                                    <p className='mt-2 mb-0'>
                                        {shipment?.driver?.name ? `${shipment?.driver?.name} |` : ""} {shipment?.carrier?.name || "-"}
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="border-bottom border-dark p-2">
                        <Col>
                            <p className="text-muted mb-1">Shipper Details</p>
                            <p className='mb-1 fw-bold'>{shipment?.shipFrom?.name} </p>
                            <span>Delivery Type -</span> {shipment?.deliveryService?.name}
                        </Col>
                    </Row>
                    <Row className="border-bottom border-dark mb-0 p-3">
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Product Name</th>
                                    <th>Reference No</th>
                                    <th>NDC</th>
                                    <th>QTY</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shipment?.items?.map((pkg, packageIndex) => {
                                    return (
                                        <>
                                            <tr key={`package-header-${packageIndex}`}>
                                                <td colSpan={5} className='border-top border-bottom border-dark p-2'>
                                                    <strong>{`Package ${packageIndex + 1}`}</strong>
                                                </td>
                                            </tr>
                                            {
                                                multi_Dimensional_Shipment_Item
                                                    ? pkg?.map((item, itemIndex) => (
                                                        <tr key={`item-${itemIndex}`} className='mb-1'>
                                                            <td>#{itemIndex + 1}</td>
                                                            <td>{item?.name || `Product ${itemIndex + 1}`}</td>
                                                            <td style={{ maxWidth: '20px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                                                {item?.number || '-'}
                                                            </td>
                                                            <td>{item?.ndc || '-'}</td>
                                                            <td>{item?.qty || '-'}</td>
                                                        </tr>
                                                    ))
                                                    : (
                                                        <tr key={`item-${packageIndex}`} className='mb-1'>
                                                            <td>#{packageIndex + 1}</td>
                                                            <td>{pkg?.name || `Product ${packageIndex + 1}`}</td>
                                                            <td style={{ maxWidth: '20px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                                                {pkg?.number || '-'}
                                                            </td>
                                                            <td>{pkg?.ndc || '-'}</td>
                                                            <td>{pkg?.qty || '-'}</td>
                                                        </tr>
                                                    )
                                            }
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Row>
                    {
                        history &&
                        <>
                            <Row className='mb-0'>
                                <div className='border-bottom border-dark my-2'>
                                    <h4 className='fw-bold'> <b>Proof of Delivery</b></h4>
                                </div>
                                <Col className="pt-2 px-3">
                                    <Row className='mb-0'>
                                        <Col>
                                            <p className='mb-1'>Signed By:</p>
                                            <p>{history?.signature?.name || "--"}</p>
                                        </Col>
                                        <Col>
                                            <p className='mb-1'>Relationship:</p>
                                            <p>{history?.signature?.relationship || "--"}</p>
                                        </Col>
                                        {closedByOtp && <Col>
                                            <p className='mb-1'>One-Time Passcode:</p>
                                            <p>{shipment?.authCode || "No"}</p>
                                        </Col>}
                                    </Row>
                                    {history?.signature?.isContactLess === "true" &&
                                        <Col>
                                            <p className='mb-1'>Contactless:</p>
                                            <p>"Yes"</p>
                                        </Col>
                                    }
                                    {history?.signature?.reason && <div>
                                        <span className='h4'> Reason: </span>
                                        <p>{history?.signature?.reason}</p>
                                    </div>}
                                </Col>
                            </Row>
                            <Row id='podImg' className=" mb-0">
                                {imageArray?.length > 0 && imageArray.map?.((item, index) => (
                                    <img
                                        className='m-2'
                                        key={index}
                                        src={item}
                                        alt={`POD ${index + 1}`}
                                        style={{ height: '120px', maxWidth: '100px', objectFit: 'contain', borderRadius: '5px' }}
                                    />
                                ))}
                            </Row>
                        </>
                    }
                </div>
                <div className='d-flex justify-content-center align-item-center mt-1'>
                    <img src={logo} alt="Phox-logo" style={{ width: '80px' }} />
                </div>
            </div>
        </div >
    );
};

export default DownloadPOD;

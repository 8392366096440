import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { TableClearFilter, TableDateFilter, TablePagination, TableShipperFilter, TimeZoneFilter } from '../../components/TableFilter';
import { handleApiError, PAGE_SIZE } from '../../helpers';

export default function ShipmentManifest() {
    const dispatch = useDispatch();
    const myShipper = useSelector((state) => state.slice.SHIPPER);

    const [shipmentManifest, setShipmentManifest] = useState([])
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();
    const [spinner, setSpinner] = useState(true)
    const [resetDate, setRestDate] = useState(0);
    const [timeZone, setTimeZone] = useState("America/New_York");
    const [tz, setTz] = useState("EST");
    const [dateFilters, setDateFilters] = useState({
        fromDate: moment()
            .tz(timeZone || "America/New_York")
            .startOf("day")
            .unix(),
        toDate: moment()
            .tz(timeZone || "America/New_York")
            .endOf("day")
            .unix(),
    });

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/shipment-manifest",
        })
    }, [])

    const tzHandle = (e) => {
        setTimeZone(e);
    };

    const getShipmentManifest = async () => {
        try {
            setSpinner(true)
            const apiName = 'api';
            const path = `/search/shipment-manifest?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
            let init = {
                body: {
                    sort: [{ "createdTime": { "order": "desc" } }],
                    query: {
                        bool: {
                            must: [{
                                range: {
                                    "createdTime": {
                                        "gte": dateFilters?.fromDate,
                                        "lte": dateFilters?.toDate
                                    }
                                }
                            }],
                            must_not: [{ match: { "deliveryService.name.keyword": "First-Class Mail" } }]
                        }
                    }
                }
            };
            if (myShipper?.shipper?.id) init.body.query.bool.must.push({ match: { shipperId: myShipper?.shipper?.id } })

            const data = await API.post(apiName, path, init);
            const sourceData = data?.hits?.hits?.length > 0 ? data?.hits?.hits?.map((item) => item?._source) : [];
            setShipmentManifest(sourceData);
            setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));
            return sourceData;

        } catch (error) {
            handleApiError(error)
        } finally { setSpinner(false) }
    }

    const clearFilters = () => {
        setDateFilters({
            fromDate: moment()
                .tz(timeZone || "America/New_York")
                .startOf("day")
                .unix(),
            toDate: moment()
                .tz(timeZone || "America/New_York")
                .endOf("day")
                .unix(),
        })
    }
    useEffect(() => {
        getShipmentManifest()
    }, [dateFilters?.toDate, myShipper?.shipper?.id])
    return (
        <>
            <PageHeader name="Shipment Manifest"></PageHeader>
            <Container fluid>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col className='col'></Col>
                            <TableShipperFilter hideAll={true} />
                            <TableDateFilter
                                key={resetDate}
                                timezone={myShipper?.shipper?.timezone?.id}
                                onChange={setDateFilters}
                            />
                            <TimeZoneFilter
                                title={""}
                                setTimeZone={setTimeZone}
                                dark={true}
                                onChange={tzHandle}
                                tz={tz}
                                setTz={setTz}
                            />
                            <TableClearFilter onClick={clearFilters} />
                        </Row>
                    </Card.Header>
                    <Spinner display={spinner}>
                        <Table responsive size="sm" className="mb-0">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Manifest ID</th>
                                    <th>Shipper Group</th>
                                    <th>Shipper</th>
                                    <th>Count</th>
                                    <th className='text-center'>Label URL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shipmentManifest?.map((manifest) => (
                                    <tr>
                                        <td>{moment(manifest.createdAt).format('MMM D, YYYY, hh:mm A')}</td>
                                        <td>{manifest?.id}</td>
                                        <td>{manifest?.shipperGroup?.name}</td>
                                        <td>{manifest?.shipper?.name}</td>
                                        <td>{manifest?.count}</td>
                                        <td className='text-center'>{manifest?.extLabelUrl &&
                                            <a target='blank' href={manifest?.extLabelUrl}> <i className='fe fe-paperclip'></i></a>}</td>
                                    </tr>
                                ))}

                            </tbody>
                            <TablePagination
                                pageNumber={pageNumber}
                                pageCount={pageCount}
                                setPageNumber={setPageNumber}
                                colSpan={8}
                            />
                        </Table>
                    </Spinner>
                </Card>
            </Container >
        </>
    )
}

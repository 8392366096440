/* eslint-disable react-hooks/exhaustive-deps */

import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { storeApp, storeCarrier, storeHost, storeShipper, storeShipperGroup, storeShipperGroups, storeShippers, storeUser } from './../stores/slice';
import Footer from './Footer';
import { LeftMenu, TopMenu } from './Menu';
import { useIdleTimer } from 'react-idle-timer';
import { FullStory } from '@fullstory/browser';


const AdminLayout = () => {

	const dispatch = useDispatch()
	const location = useLocation();
	const navigate = useNavigate();

	const searchParams = new URLSearchParams(location.search);
	const isFullscreen = searchParams.get('fullscreen');
	const [fullScreen, setFullScreen] = useState(false);

	useEffect(() => getSessionData(), [])

	const getSessionData = () => {
		Auth.currentSession().then((session) => {
			let data = localStorage.getItem('phsp')
			if (data) {
				data = JSON.parse(data);
				dispatch(storeHost(data.HOST));
				dispatch(storeUser(data.USER));
				dispatch(storeApp(data.APP));
				dispatch(storeShipperGroup(data.SHIPPER_GROUP));
				dispatch(storeShipperGroups(data.SHIPPER_GROUPS));
				dispatch(storeShipper(data.SHIPPER));
				dispatch(storeShippers(data.SHIPPERS));
				dispatch(storeCarrier(data.CARRIER));
				FullStory('setIdentity', {
					uid: data.USER?.id,
					properties: {
						displayName: data?.USER?.name,
						email: data?.USER?.email,
						shipper: data?.SHIPPER?.shipper?.name || data?.SHIPPER?.shipper?.alias,
						role: data?.SHIPPER?.role,
					}
				})
			}
		}).catch((error) => {
			window.location = '/auth/logout'
		})
	}

	const onIdle = () => {
		if (!fullScreen) navigate('/auth/logout');
	}

	useIdleTimer({ onIdle, timeout: 900_000, throttle: 500 })

	const handleFullScreen = () => {

		if (!('fullscreenElement' in document)) {
			console.warn('Full-screen API is not supported in this browser.');
			return;
		}

		const elem = document.documentElement;

		const isFullscreen =
			document.fullscreenElement ||
			document.webkitFullscreenElement ||
			document.mozFullScreenElement ||
			document.msFullscreenElement;

		if (!isFullscreen) {
			const requestFullScreen =
				elem.requestFullscreen ||
				elem.webkitRequestFullscreen ||
				elem.mozRequestFullScreen ||
				elem.msRequestFullscreen;

			if (requestFullScreen) {
				requestFullScreen.call(elem)
					.then(() => setFullScreen(true))
					.catch(err => console.error(`Error enabling full-screen mode: ${err.message} (${err.name})`));
			}
		} else {
			const exitFullScreen =
				document.exitFullscreen ||
				document.webkitExitFullscreen ||
				document.mozCancelFullScreen ||
				document.msExitFullscreen;

			if (exitFullScreen) {
				exitFullScreen.call(document)
					.then(() => setFullScreen(false))
					.catch(err => console.error(`Error exiting full-screen mode: ${err.message} (${err.name})`));
			}
		}
	};

	useEffect(() => {
		const onFullScreenChange = () => {
			const isFullscreen =
				document.fullscreenElement ||
				document.webkitFullscreenElement ||
				document.mozFullScreenElement ||
				document.msFullscreenElement;

			setFullScreen(!!isFullscreen);
		};

		// Listen for fullscreen change events
		document.addEventListener('fullscreenchange', onFullScreenChange);
		document.addEventListener('webkitfullscreenchange', onFullScreenChange);
		document.addEventListener('mozfullscreenchange', onFullScreenChange);
		document.addEventListener('MSFullscreenChange', onFullScreenChange);

		// Cleanup event listeners on component unmount
		return () => {
			document.removeEventListener('fullscreenchange', onFullScreenChange);
			document.removeEventListener('webkitfullscreenchange', onFullScreenChange);
			document.removeEventListener('mozfullscreenchange', onFullScreenChange);
			document.removeEventListener('MSFullscreenChange', onFullScreenChange);
		};
	}, []);

	return (
		<>
			{
				(isFullscreen || fullScreen) ? <div className='main-content'>
					<Outlet context={{ fullScreen, handleFullScreen }} />
				</div> : <>
					<LeftMenu />
					<div className='main-content'>
						<Outlet context={{ fullScreen, handleFullScreen }} />
						<Footer />
					</div>
				</>
			}
		</>
	);
};

export default AdminLayout;

/* eslint-disable react-hooks/exhaustive-deps */
import { API } from "aws-amplify";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Container, Row, Table } from "react-bootstrap";
import ReactGA from "react-ga4";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux';
import { StatCard } from "../../components/Card";
import { DriverFilter, ServiceTypeFilter, UserCarrierFilter } from "../../components/FilterWithIds";
import PageHeader from "../../components/PageHeader";
import Spinner from "../../components/Spinner";
import { StatusBadge } from "../../components/StatusBadge";
import {
    GreenPhoxFilter,
    StatusFilter,
    TableClearFilter,
    TableDateFilter,
    TableExportFilter,
    TableKeywordSearch,
    TablePagination,
    TableShipperFilter,
    TimeZoneFilter
} from "../../components/TableFilter";
import {
    DELAY_TIMEOUT,
    handleApiError,
    isOwner,
    PAGE_SIZE,
    toLocalDate,
    toLocalTime
} from "../../helpers";
import { storeShipper } from '../../stores/slice';

const deliveryServicesByShipperId = /* GraphQL */ `
  query DeliveryServicesByShipperId(
    $shipperId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deliveryServicesByShipperId(
      shipperId: $shipperId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        value
        pickupBy
        tatMin
        tatMax
        maxDistance
        default
        sort
        shipperId
        carrierId
        carrier{
            id
            name
            alias
            image
			active
			 timezone {
          alias
          id
          name
        }
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;


const BillingReport = () => {
    const dispatch = useDispatch();

    const myShipper = useSelector((state) => state.slice.SHIPPER);
    const myShippers = useSelector((state) => state.slice.SHIPPERS);

    const [resetDate, setRestDate] = useState(0);
    const [spinner, showSpinner] = useState(false);
    const [shipments, setShipments] = useState([]);
    const [facets, setFacets] = useState({});
    const [statusCount, setStatusCount] = useState();

    const [keyword, setKeyword] = useState("");
    const [status, setStatus] = useState();

    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();

    const [deliveryService, setDeliveryService] = useState({});
    const [deliveryServicesList, setDeliveryServicesList] = useState([]);
    const [carrier, setCarrier] = useState({})
    const [carrierList, setCarrierList] = useState([])
    const [driver, setDriver] = useState();
    const [isGreenPhox, setIsGreenPhox] = useState();
    const [timeZone, setTimeZone] = useState("America/New_York");
    const [tz, setTz] = useState("EST");

    const [dateFilters, setDateFilters] = useState({
        fromDate: moment()
            .tz(timeZone || "America/New_York")
            ?.startOf("day")
            .unix(),
        toDate: moment()
            .tz(timeZone || "America/New_York")
            ?.endOf("day")
            .unix(),
    });

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/billing-report",
        })
    }, [])

    useEffect(() => {
        if (myShipper?.shipper?.id || myShippers?.length > 0) {
            getElasticShipment();
        }
        if (pageNumber) { setPageNumber(0) };
    }, [status, myShipper, carrier, deliveryService, isGreenPhox, driver, pageNumber]);


    useEffect(() => {
        setTimeZone(myShipper?.shipper?.timezone?.id || carrier?.timezone?.id)
        setTz(myShipper?.shipper?.timezone?.alias || carrier?.timezone?.alias)
    }, [carrier])
    useEffect(() => {
        if (myShipper) {
            getServices()
            setTimeZone(myShipper?.shipper?.timezone?.id)
            setTz(myShipper?.shipper?.timezone?.alias)
        }
    }, [myShipper])

    useEffect(() => {
        const delay = setTimeout(() => {
            setPageNumber(0);
            if (myShipper?.shipper?.id || myShippers?.length > 0) {
                getElasticShipment();
            }
        }, DELAY_TIMEOUT);
        return () => clearTimeout(delay);
    }, [keyword, dateFilters]);

    const getServices = () => {
        setCarrier(null)
        myShipper?.shipper?.id && API.graphql({ query: deliveryServicesByShipperId, variables: { shipperId: myShipper?.shipper?.id } }).then((response) => {
            const deliveryServices = response.data.deliveryServicesByShipperId?.items.sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' }));
            setDeliveryServicesList(deliveryServices)

            let carriers = [];
            deliveryServices.forEach((item) => {
                if (!carriers.find(x => x?.id === item?.carrier?.id))
                    carriers.push(item.carrier)
            });

            carriers = carriers.filter((carrier) => carrier?.active !== false).sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' }))
            setCarrierList(carriers)
        })
    }

    const getElasticShipment = async () => {
        showSpinner(true);

        try {
            const apiName = 'api';
            const path = `/search/shipment?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
            let init = {
                body: {
                    aggs: {
                        status: {
                            terms: {
                                "field": "status.keyword",
                                "size": 100
                            }
                        }
                    },
                    sort: [
                        { "_score": { "order": "desc" } },
                        {
                            "actualDeliveryTime": {
                                "order": "desc",
                                "unmapped_type": "date"
                            }
                        }
                    ],
                    query: {
                        bool: {
                            must: [],
                            must_not: []
                        }
                    },
                }
            };


            if (!keyword) {
                init.body.query.bool.must.push({
                    range: {
                        "expectedDeliveryTime": {
                            "gte": dateFilters?.fromDate,
                            "lte": dateFilters?.toDate
                        }
                    }
                })
            }

            if (keyword) {
                let fields = [
                    "shipTo.name^3",
                    "shipTo.first_name^2",
                    "shipTo.last_name",
                    "shipTo.phone",
                    "extId",
                    "customer.extId",
                    "shipTo.address.address1",
                    "shipTo.address.postalCode"
                ]
                if (+keyword) {
                    fields.push("number")
                }
                init.body.query.bool.must.push(
                    {
                        "multi_match": {
                            "query": keyword,
                            "fields": fields
                        }
                    }
                )
                if (keyword.includes("-")) { // RX number includes '-'
                    init.body.query.bool.must.pop()
                    init.body.query.bool.filter = {
                        "terms": {
                            "items.number.keyword": [keyword]
                        }
                    }
                }
            }

            if (myShipper?.shipper?.id) init.body.query.bool.must.push({ match: { shipperId: myShipper?.shipper?.id } })
            else if (myShippers) {
                let shipperIdArr = []
                myShippers.forEach((item) => {
                    shipperIdArr.push(item.shipper.id)
                })
                init.body.query.bool.must.push({ "terms": { "shipperId": shipperIdArr } })
            }

            if (carrier?.id) init.body.query.bool.must.push({ match: { carrierId: carrier?.id } })
            if (driver) {
                if (driver?.id == "driverNotAssigned") {
                    init.body.query.bool.must_not.push({ exists: { field: "driver.name" } })
                } else { init.body.query.bool.must.push({ match: { driverId: driver?.id } }) }
            }
            if (deliveryService?.id) init.body.query.bool.must.push({ match: { deliveryServiceId: deliveryService?.id } })
            if (status) init.body.query.bool.must.push({ match: { status: status } });

            if (isGreenPhox?.value === false) init.body.query.bool.must.push({ match: { isGreenPhox: isGreenPhox?.value } })
            else if (isGreenPhox?.value === true) init.body.query.bool.must.push({ match: { isGreenPhox: isGreenPhox?.value } })

            const data = await API.post(apiName, path, init);

            let statusCount = {};
            data.aggregations.status.buckets?.forEach((item) => {
                statusCount[item.key] = item.doc_count
            })

            setFacets(data.aggregations)
            setStatusCount(statusCount)

            setShipments(data?.hits?.hits);
            setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));
            showSpinner(false);
        } catch (error) {
            handleApiError(error)
            showSpinner(false);

        }
    }
    const downloadReport = async () => {
        let downloadingToastId;
        try {
            downloadingToastId = toast.loading("Downloading...");
            let shipperId = null;
            if (myShipper?.shipper?.id)
                shipperId = myShipper.shipper.id;
            else if (myShippers) {
                let shipperFilter = [];
                myShippers.forEach((item) => shipperFilter.push(`${item.shipper.id}`));
                shipperId = shipperFilter;
            }
            const apiName = 'api';
            const path = "/report/ship/billing";
            const init = {
                body: {
                    expectedDeliveryTime: {
                        fromDate: dateFilters?.fromDate ?? null,
                        toDate: dateFilters?.toDate ?? null
                    },
                    shipperId: shipperId ?? null,
                    carrierId: carrier?.id ?? null,
                    status: status || null,
                    deliveryServiceId: deliveryService?.id ?? null,
                    driverId: driver?.id ?? null,
                    timezone: timeZone,
                }
            };

            const { url } = await API.post(apiName, path, init);
            if (url) {
                const linkTag = document.createElement('a');
                linkTag.href = url;
                linkTag.click();
                linkTag.remove();
                toast.remove(downloadingToastId);
                toast.success("Download finished");
            } else return toast.error("No data found !")

        } catch (error) {
            handleApiError(error)
        } finally { toast.dismiss(downloadingToastId) }
    };


    const clearFilters = () => {
        setRestDate(prev => prev === 0 ? prev = 1 : prev = 0)
        setStatus();
        setKeyword("");
        setCarrier()
        setCarrierList([])
        setDeliveryService({})
        setDeliveryServicesList([])
        setPageNumber(0);
        setIsGreenPhox(null)
        setDriver("")
        setDateFilters({
            fromDate: moment()
                .tz("America/New_York")
                ?.startOf("day")
                .unix(),
            toDate: moment()
                .tz("America/New_York")
                ?.endOf("day")
                .unix(),
        });
        setTimeZone("America/New_York");
        setTz("EST");
        dispatch(storeShipper(myShippers))
    };


    const tzHandle = (e) => {
        setTimeZone(e);
    };

    return (
        <>
            <PageHeader name="Billing" />
            <Container fluid>
                <Row>
                    <StatCard
                        title="Ready for Pickup / Open"
                        value={(statusCount?.READY_FOR_PICKUP || 0) + (statusCount?.OPEN || 0)}
                        icon="box"
                    />
                    <StatCard
                        title="Out for Delivery"
                        value={
                            (statusCount?.OUT_FOR_DELIVERY || 0) +
                            (statusCount?.IN_TRANSIT || 0)
                        }
                        icon="truck"
                    />
                    <StatCard
                        title="Delivered"
                        value={statusCount?.DELIVERED || 0}
                        icon="check-circle"
                    />
                    <StatCard
                        title="Exceptions / Returns"
                        value={
                            (statusCount?.EXCEPTION || 0) +
                            (statusCount?.OUT_FOR_RETURN || 0) +
                            (statusCount?.RETURNED || 0)
                        }
                        icon="alert-triangle"
                    />
                </Row>
                <Card>
                    <Card.Header>
                        <Row>
                            <TableKeywordSearch keyword={keyword} onChange={setKeyword} />
                            <TableShipperFilter />
                            <UserCarrierFilter onChange={setCarrier} value={carrier} carrierList={carrierList} />

                            {carrier?.id && <DriverFilter onChange={setDriver} value={driver} shipper={myShipper?.shipper?.id} carrier={carrier} />}
                            {carrier?.id && <ServiceTypeFilter carrierId={carrier?.id} onChange={setDeliveryService} value={deliveryService} serviceList={deliveryServicesList} />}

                            <StatusFilter onChange={setStatus} value={status} />
                            <GreenPhoxFilter value={isGreenPhox} onChange={setIsGreenPhox} />
                            <TableDateFilter
                                key={resetDate}
                                timezone={myShipper?.shipper?.timezone?.id}
                                onChange={setDateFilters}
                                isBilling={true}
                            />
                            {isOwner(myShipper?.role) &&
                                <TableExportFilter option={"Download Billing Report"} onChange={downloadReport} />
                            }
                            <TimeZoneFilter
                                title={""}
                                setTimeZone={setTimeZone}
                                dark={true}
                                onChange={tzHandle}
                                tz={tz}
                                setTz={setTz}
                            />
                            <TableClearFilter onClick={clearFilters} />
                        </Row>
                    </Card.Header>
                    <Spinner display={spinner}>
                        <Table responsive size="sm" className="mb-0">
                            <thead>
                                <tr>
                                    <th>Shipment #</th>
                                    <th>Shipper</th>
                                    <th>Driver / Courier</th>
                                    <th className='text-center'>Delivered At</th>
                                    <th className='text-center'>Total Shipper Billing </th>
                                    <th className='text-center'>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shipments.map((shipment) => {

                                    return (
                                        <tr key={shipment._source.id} style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                                if (e.metaKey || e.ctrlKey) {
                                                    window.open(`/shipment/${shipment._source?.id}`, '_blank');
                                                } else {
                                                    window.open(`/shipment/${shipment._source?.id}`, '_self');
                                                }
                                            }}>
                                            <td >
                                                <div>
                                                    {shipment._source?.isGreenPhox ? <img src="img/greenphox.svg" alt="green-phox-icon rounded-circle" className="mx-2" height={'20px'} width={'20px'} /> : <small className="ms-5"> </small>}
                                                    {shipment?._source?.number}
                                                </div>
                                                {shipment.batch_number > 0 && (
                                                    <div className="small text-muted">
                                                        Batch #{shipment?._source?.batch_number}
                                                    </div>
                                                )}
                                            </td>

                                            <td>
                                                {shipment._source.shipperGroup?.name}
                                                <div className='small text-muted'>{shipment._source.shipFrom?.name}</div>
                                            </td>
                                            <td>
                                                <div>{shipment?._source.driver?.name || "Unassigned"}</div>
                                                <div className="text-small text-muted">
                                                    {shipment?._source.carrier?.alias}
                                                </div>
                                            </td>
                                            <td className='text-center'>
                                                {
                                                    shipment?._source.actualDeliveryTime && <>
                                                        {toLocalTime(shipment?._source?.actualDeliveryTime, shipment?._source?.shipFrom?.timezone?.id)}
                                                        <div className='small text-muted'>{toLocalDate(shipment?._source?.actualDeliveryTime, shipment?._source?.shipFrom?.timezone?.id)}</div>
                                                    </>
                                                }
                                            </td>
                                            <td className='text-center'>
                                                <div>{shipment._source?.billing_shipper ? "$" + Number(JSON.parse(shipment?._source?.billing_shipper)?.total).toFixed(2) : "-"} </div>
                                            </td>

                                            <td className="text-center">
                                                <StatusBadge status={shipment?._source.status} />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <TablePagination
                                pageNumber={pageNumber}
                                pageCount={pageCount}
                                setPageNumber={setPageNumber}
                                colSpan={8}
                            />
                        </Table>
                    </Spinner>
                </Card>
            </Container>
        </>
    );
};

export default BillingReport;

import { MapView } from '@aws-amplify/ui-react-geo';
import '@aws-amplify/ui-react/styles.css';
import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, ListGroup, Row } from 'react-bootstrap';
import ReactImageGallery from 'react-image-gallery';
import { useParams } from 'react-router-dom';
import awsconfig from "../../aws-exports";
import { AddressView } from '../../components/Address';
import { DriverMarker, ShipmentMarker, ShipperMarker } from '../../components/MapMarker';
import { MapToggleButton } from '../../components/TableFilter';
import { PAGE_TITLE, statusBadgeColor, toLocalDateTime } from '../../helpers';
import { useIdleTimer } from 'react-idle-timer';
import ReactGA from "react-ga4"

const onShipmentRouteUpdate = /* GraphQL */ `
  subscription ShipmentRouteSubscription ($filter: ModelSubscriptionShipmentRouteFilterInput) {
    onUpdateShipmentRoute(filter: $filter) {
		id
		routeSequence
		routeDuration
		routeDistance
		updatedAt
	}
  }
`;

const onUserLocationUpdate = /* GraphQL */ `
  subscription UserLocationSubscription ($filter: ModelSubscriptionUserLocationFilterInput) {
    onUpdateUserLocation(filter: $filter) {
      	id
	  	latitude
    	longitude
    }
  }
`;

const onUpdateShipment = /* GraphQL */ `
  subscription OnUpdateShipment($filter: ModelSubscriptionShipmentFilterInput) {
    onUpdateShipment(filter: $filter) {
      id
      status
	  actualDeliveryTime
    }
  }
`;
const Tracker = () => {

	const { id } = useParams()

	const [viewState, setViewState] = useState({});
	const [shipment, setShipment] = useState({});
	const [driver, setDriver] = useState({})
	const [mapStyle, setMapStyle] = useState(awsconfig.geo.amazon_location_service.maps.default);

	const [cardView, setCardView] = useState('status')
	const [cardIndex, setCardIndex] = useState(0)

	const [timeRemaining, setTimeRemaining] = useState();

	const [activeUser, setActiveUser] = useState(true)

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: `/t/${id}`,
		})
	}, [])
	useEffect(() => {
		document.title = `Shipment #${shipment?.number || ""} | ${shipment?.shipFrom?.name || PAGE_TITLE}`;
	}, [shipment?.number])

	const getShipmentById = async (id) => {
		try {
			const shipment = await API.get("api", `/public/shipment/${id}`)
			let diffInMins = moment().diff(moment(shipment?.route?.updatedAt), "minutes")
			setTimeRemaining((shipment?.route?.routeDuration - diffInMins) * 60)
			return shipment;
		} catch (err) {
			throw new Error('Failed to fetch shipment: ' + err.message);
		}
	};

	useEffect(() => {
		getShipmentById(id).then((shipment) => {
			if (shipment) {
				const { latitude, longitude } = shipment.shipTo?.address?.location;
				const { latitude: shipFromLatitude, longitude: shipFromLongitude } = shipment.shipFrom?.address?.location;
				setShipment(shipment);

				let driver = shipment.driver || {};
				driver.name = 'Phox Health';
				setDriver(driver);

				const minLat = Math.min(parseFloat(latitude), parseFloat(shipFromLatitude));
				const maxLat = Math.max(parseFloat(latitude), parseFloat(shipFromLatitude));
				const minLng = Math.min(parseFloat(longitude), parseFloat(shipFromLongitude));
				const maxLng = Math.max(parseFloat(longitude), parseFloat(shipFromLongitude));

				setViewState({
					bounds: [
						[minLng, minLat],
						[maxLng, maxLat]
					],
					fitBoundsOptions: {
						padding: 200
					}
				});
			}
		});
	}, [id]);

	useEffect(() => {
		if (viewState.bounds && mapRef.current) {
			mapRef.current.fitBounds(viewState.bounds, viewState.fitBoundsOptions);
		}
	}, [viewState]);

	useEffect(() => {
		const shipmentRouteSubscription = API.graphql({ query: onShipmentRouteUpdate, variables: { filter: { id: { eq: id } } } }).subscribe({
			next: (response) => {
				const route = response.value.data.onUpdateShipmentRoute;
				setShipment({ ...shipment, route: route })
				let diffInMins = moment().diff(moment(route?.updatedAt), "minutes")
				setTimeRemaining((shipment?.route?.routeDuration - diffInMins) * 60)
			},
			error: (error) => console.error('Subscription error:', error),
		});

		const userLocationSubscription = API.graphql({ query: onUserLocationUpdate, variables: { filter: { id: { eq: shipment?.driverId } } } }).subscribe({
			next: (response) => {
				const userLocation = response.value.data.onUpdateUserLocation;
				setDriver({ ...driver, location: userLocation })
			},
			error: (error) => console.error('Subscription error:', error),
		});

		const shipmentUpdateSubscription = API.graphql({ query: onUpdateShipment, variables: { filter: { id: { eq: shipment?.id } } } }).subscribe({
			next: (response) => {
				const newStatus = response.value.data.onUpdateShipment.status;
				const deliveryTime = response.value.data.onUpdateShipment.actualDeliveryTime;
				setShipment({ ...shipment, status: newStatus, actualDeliveryTime: deliveryTime })
			},
			error: (error) => console.error('Subscription error:', error),
		});

		if (activeUser === false) {
			shipmentRouteSubscription.unsubscribe();
			userLocationSubscription.unsubscribe();
			shipmentUpdateSubscription.unsubscribe();
		}

		return () => {
			shipmentRouteSubscription.unsubscribe();
			userLocationSubscription.unsubscribe();
			shipmentUpdateSubscription.unsubscribe();
		};
	}, [driver, shipment, activeUser]);

	const mapRef = useRef();

	useEffect(() => {
		setCardView(cardSlider[cardIndex])
	}, [cardIndex])

	const goToFeedbackPage = () => {
		return window.location.href = `/f/${shipment?.id}?utm_medium=URL`
	}


	const onIdle = () => {
		console.log('Idle');
		setActiveUser(false)
	}

	useIdleTimer({
		onIdle,
		timeout: 900_000,
		throttle: 500
	})


	let cardSlider = ['status']
	if (shipment?.attachment?.length > 0) {
		cardSlider.push('POD')
	}


	useEffect(() => {
		const timer = setInterval(() => {
			setTimeRemaining(prevTime => prevTime - 1);
		}, 1000);
	}, []);

	let liveHours = Math.floor(timeRemaining / 3600);
	let liveMinutes = Math.floor((timeRemaining % 3600) / 60);
	let liveETA = liveHours > 0 ? `${liveHours} hr ${liveMinutes} min` : `${liveMinutes} min`;


	const continueTracking = () => {
		setActiveUser(true)
		getShipmentById(id)
	}

	return (
		<>
			<section className='position-relative'>

				{
					!activeUser &&
					<>
						<div className="overlay-tracking"></div>
						<section className='tracking-card-container rounded'>
							<Container fluid>
								<Row className='h-100'>
									{
										<Col md={4} xs={8} className='bg-white rounded-bottom text-center'>
											<h2 className='p-2 mt-4 '>Please click <b>"Track"</b> to resume real-time tracking of your delivery </h2>
											<Button className='p-3 m-3 btn-dark' onClick={() => continueTracking()} > Track </Button>
										</Col>
									}
								</Row>
							</Container>
						</section>

					</>
				}

				{
					(shipment && viewState) &&
					< MapView initialViewState={viewState} maxZoom={20} minZoom={2} ref={mapRef} mapStyle={mapStyle}>

						<div className='phox-font mx-2 mt-3 row d-flex flex-grow-1 flex-column'>
							<div className='col-md-6 col-lg-3 col-sm-6'>
								<Card>

									<Card.Body>
										<div className='d-flex justify-content-center'>
											<img src={`${shipment?.shipper?.image ? shipment?.shipper?.image : '/img/logo.svg'} `} style={{ width: '200px' }} alt='logo' />
										</div>
										<hr />
										{cardView === 'status' &&
											<>
												{
													shipment.status === 'IN_TRANSIT' &&
													<div className='lead text-center'>
														Courier has picked up your package, we'll let you know once they are on their way for delivery.
														<hr />
													</div>
												}

												{
													shipment.status === 'OUT_FOR_DELIVERY' &&
													<div className='lead text-center'>

														{
															shipment?.route?.routeSequence > 1 && liveMinutes <= 0 && <>
																Courier will arrive shortly. For more information contact us at (844) 688-7469
															</>
														}
														{
															shipment?.route?.routeSequence > 1 && liveMinutes > 0 && <>
																The courier is {shipment?.route?.routeSequence} stops and {liveETA} away
															</>
														}

														{
															shipment?.route?.routeSequence === 1 && liveMinutes > 0 && <>
																Courier should arrive in about {liveETA}
															</>
														}
														{
															shipment?.route?.routeSequence === 1 && liveMinutes <= 0 &&
															<>
																Courier will arrive shortly. For more information contact us at (844) 688-7469
															</>
														}

														{
															((shipment?.route?.routeSequence === -1) && shipment?.expectedDeliveryTime) &&
															<>
																The driver is on the way for delivery, with the expected delivery time being today at {moment.unix(shipment?.expectedDeliveryTime).format('hh:mm a')}
															</>
														}
														<hr />
													</div>
												}

												{
													shipment.status === 'DELIVERED' &&
													<div className='lead'>
														Your package was delivered on {toLocalDateTime(shipment.actualDeliveryTime, shipment?.shipFrom?.timezone?.id, shipment?.shipFrom?.timezone?.alias, true)}
														<hr />
													</div>
												}

												{
													shipment.status === 'OUT_FOR_RETURN' &&
													<div className='lead'>
														Unfortunately, we were unable to deliver your package today. Please contact us or the pharmacy to best coordinate another attempt.
														<hr />
													</div>
												}

												{
													shipment.status === 'RETURNED' &&
													<div className='lead'>
														The shipment has been returned back to the Pharmacy. Please contact us or the pharmacy to best coordinate another attempt.
														<hr />
													</div>
												}

												<ListGroup className='list-group-flush list-group-activity my-3'>
													<ListGroup.Item>
														<Row>
															<Col className='col-auto'>
																<div className='avatar avatar-sm'>
																	<div className='avatar-title fs-lg badge bg-dark rounded-circle'>
																		<i className='fe fe-truck'></i>
																	</div>
																</div>
															</Col>
															<Col>
																<h4 className='mb-2'>{shipment?.shipFrom?.name}</h4>
																<AddressView address={shipment?.shipFrom?.address} />
															</Col>
														</Row>
													</ListGroup.Item>

													<ListGroup.Item>
														<Row>
															<Col className='col-auto'>
																<div className='avatar avatar-sm'>
																	<div className={`avatar-title fs-lg badge bg-${statusBadgeColor(shipment.status)} rounded-circle`}>
																		<i className='fe fe-home'></i>
																	</div>
																</div>
															</Col>
															<Col>
																<h4 className='mb-2'>{shipment?.shipTo?.name}</h4>
																<AddressView address={shipment?.shipTo?.address} />
															</Col>
														</Row>
													</ListGroup.Item>


												</ListGroup>
											</>
										}

										{cardView === 'POD' &&
											<>

												<div className="position-relative my-3 map-slider">
													<ReactImageGallery
														showBullets={false}
														showThumbnails={false}
														lazyLoad={true}
														showPlayButton={false}
														showFullscreenButton={false}
														items={shipment?.attachment?.map((item) => ({
															original: item,
														}))}
													/>
												</div>

											</>
										}

									</Card.Body>

									{cardSlider?.length > 1 &&
										<Card.Footer>
											<Row className='d-flex justify-content-between'>
												<Col className='d-flex justify-content-start'>
													{cardIndex !== 0 &&
														<i className='fe fe-arrow-left display-4 cursor-pointer' title='previous card' onClick={() => setCardIndex(cardIndex - 1)}>  </i>
													}
												</Col>
												{
													shipment?.status === "DELIVERED" &&
													<Col className='d-flex justify-content-start'>
														<Button className='btn btn-light btn-sm' onClick={() => goToFeedbackPage()}> Rate Us </Button>
													</Col>
												}
												<Col className='d-flex justify-content-end'>
													{cardSlider.length - 1 !== cardIndex &&
														<i className='fe fe-arrow-right display-4 cursor-pointer' title='next card' onClick={() => setCardIndex(cardIndex + 1)}>  </i>
													}
												</Col>
											</Row>
										</Card.Footer>
									}
								</Card>

							</div>
						</div>

						<ShipperMarker shipper={shipment?.shipFrom} />
						<ShipmentMarker shipment={shipment} />

						{
							shipment.status === 'OUT_FOR_DELIVERY' &&
							<DriverMarker driver={driver} count={1} />
						}

						<div className="" style={{ position: 'fixed', top: '90vh', right: '25px', zIndex: 2 }}>
							<MapToggleButton mapStyle={mapStyle} setMapStyle={setMapStyle} />
						</div>

					</MapView >
				}

			</section>



		</>

	)
}

export default Tracker

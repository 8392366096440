/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "api",
            "endpoint": "https://i5dxd0epra.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://aqpkbztdefdghnowlf7gsxc2ny.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-c6uvpvrmlnesxcg7ch2d7rxqde",
    "aws_cognito_identity_pool_id": "us-east-1:14e6ef34-31f1-47b1-b1cc-e02781049d35",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_3nv8eehqV",
    "aws_user_pools_web_client_id": "475dk8evfog17qoohdbpu7n50l",
    "oauth": {
        "domain": "sso.phoxhealth.com.auth.us-east-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000,http://localhost:4200,https://hq.phoxhealth.com,https://hq.staging.phoxhealth.com,https://metrohealth.phoxhealth.com,https://my.phoxhealth.com,https://my.staging.phoxhealth.com,https://trax.phoxhealth.com,https://trax.staging.phoxhealth.com,https://trax.v5.phoxhealth.com,https://trinity.phoxhealth.com,https://trinity.staging.phoxhealth.com,trax://",
        "redirectSignOut": "http://localhost:3000/auth/logout,http://localhost:4200/auth/logout,https://hq.phoxhealth.com/auth/logout,https://hq.staging.phoxhealth.com/auth/logout,https://metrohealth.phoxhealth.com/auth/logout,https://my.phoxhealth.com/auth/logout,https://my.staging.phoxhealth.com/auth/logout,https://trax.phoxhealth.com/auth/logout,https://trax.staging.phoxhealth.com/auth/logout,https://trax.v5.phoxhealth.com/auth/logout,https://trinity.phoxhealth.com/auth/logout,https://trinity.staging.phoxhealth.com/auth/logout,trax://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "phoxhealth-dfc14175110825-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "maps": {
                "items": {
                    "here-prod": {
                        "style": "VectorHereExplore"
                    },
                    "satellite-prod": {
                        "style": "HybridHereExploreSatellite"
                    }
                },
                "default": "here-prod"
            }
        }
    }
};


export default awsmobile;

import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import FormLabel from '../../components/FormLabel';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { handleApiError, toTitleCase } from '../../helpers';
import { storeUser } from '../../stores/slice';
import ReactGA from "react-ga4"

const updateUserMutation = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      name
      title
      company
      phone
      email
	  role
    }
  }
`;

const Profile = () => {

	const dispatch = useDispatch();
	const myUser = useSelector((state) => state.slice.USER)
	const [spinner, showSpinner] = useState(false);
	const [errors, setErrors] = useState();

	const [user, setUser] = useState();

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/profile",
		})
	}, [])

	useEffect(() => {
		setUser({
			id: myUser?.id,
			name: toTitleCase(myUser?.name),
			title: myUser?.title,
			company: myUser?.company,
			email: myUser?.email,
			phone: myUser?.phone
		})
	}, [myUser]);


	const handleChange = async (e) => { setUser((data) => ({ ...data, [e.target.name]: e.target.name === 'name' ? toTitleCase(e?.target?.value) : e?.target?.value })); }

	const isFormValid = async () => {
		const error = {};

		if (!user?.name?.trim()) error['name'] = 'Name is required'
		setErrors(error);

		return Object.keys(error).length === 0;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (await isFormValid()) {
			showSpinner(true)

			API.graphql({ query: updateUserMutation, variables: { input: user } })
				.then((response) => {
					toast.success(`Profile has been updated.`)
					dispatch(storeUser(response?.data?.updateUser));
					showSpinner(false);
				})
				.catch((error) => handleApiError(error))
				.finally(() => showSpinner(false))
		}
	};

	return (
		<>
			<PageHeader name='My Profile' />

			<Spinner display={spinner}>
				<Container fluid>
					<Form onSubmit={handleSubmit}>
						<Form.Group>
							<FormLabel required={true}>Name </FormLabel>
							<Form.Control type='text' name='name' placeholder='e.g. John Doe' value={user?.name || ''} onChange={handleChange} isInvalid={!!errors?.name} />
							<Form.Control.Feedback type='invalid'>{errors?.name}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className='mt-4'>
							<FormLabel>Title</FormLabel>
							<Form.Control type='text' name='title' placeholder='e.g. Product Specialist' value={user?.title || ''} onChange={handleChange} />
						</Form.Group>
						<Form.Group className='mt-4'>
							<FormLabel>Company</FormLabel>
							<Form.Control type='text' name='company' placeholder='e.g. Phox Health Inc' value={user?.company || ''} onChange={handleChange} />
						</Form.Group>
						<Form.Group className='mt-4'>
							<FormLabel>Email</FormLabel>
							<Form.Control type='email' name='email' placeholder='e.g. john@phoxhealth.com' defaultValue={user?.email || ''} disabled />
						</Form.Group>
						<Form.Group className='mt-4'>
							<FormLabel>Phone</FormLabel>
							<PhoneInput country='us' onlyCountries={['us']} value={user?.phone} placeholder='e.g. 998-776-5543' disabled />
						</Form.Group>
						<hr />
						<div className='d-flex justify-content-start'>
							<button className='btn btn-dark me-2' type='submit'>Save</button>
						</div>
					</Form>
				</Container>
			</Spinner>
		</>
	);
};

export default Profile;

import { API } from 'aws-amplify';
import { useLottie } from 'lottie-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import uniqid from 'uniqid';
import Spinner from '../../components/Spinner';
import Angry from './images/angry.json';
import Average from './images/average.json';
import Awesome from './images/excite.json';
import Super from './images/good.json';
import Bad from './images/sad.json';
import success from './images/success.gif';
import celebration from './images/thankyou.json';
import ReactGA from "react-ga4"
import { handleApiError } from '../../helpers';

export const createShipmentRating = /* GraphQL */ `
  mutation CreateShipmentRating($input: CreateShipmentRatingInput! $condition: ModelShipmentRatingConditionInput) {
    createShipmentRating(input: $input, condition: $condition) {
      id
    }
  }
`;

const sendSlackAlert = /* GraphQL */ `
  mutation SendSlackAlert($input: SendSlackAlertInput!) {
    sendSlackAlert(input: $input)
  }
`;

const Feedback = () => {

	const { id } = useParams()
	const navigate = useNavigate()
	const [showNext, setShowNext] = useState(false)
	const [feedbackData, setFeedbackData] = useState('')
	const ratingNames = ['VERY BAD', 'BAD', 'AVERAGE', 'GOOD', 'LOVED IT'];
	const [rating, setRating] = useState(null);
	const [NPS, setNPS] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [spinner, showSpinner] = useState(true);
	const [shipment, setShipment] = useState({})

	const [thanks, setThanks] = useState(false)

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: `/f/:${id}`,
		})
		document.title = "Public Feedback"
	}, [])

	const animationData = [Awesome, Angry, Bad, Average, Super, Awesome];
	const NPSanimationData = [Awesome, Angry, Angry, Angry, Bad, Bad, Average, Average, Super, Super, Awesome, Awesome];
	const ratingDescription = ['Tell us more...', 'Tell us more...', 'Tell us your suggestions...', 'Tell us what you liked most...', 'Tell us what you loved...'];
	const options = { animationData: animationData[rating - 0] || Angry, loop: true };
	const NPSoptions = { animationData: NPSanimationData[NPS - 0] || Angry, loop: true };

	const { View: RatingView } = useLottie(options);
	const { View: NPSView } = useLottie(NPSoptions);

	const handleRating = (index) => setRating(index + 1)
	const handleNPS = (index) => setNPS(index + 1)
	let placeholder = rating ? ratingDescription[rating - 1] : 'Tell us more...';


	const getShipmentById = async (id) => {
		try {
			const shipmentData = await API.get("api", `/public/shipment/${id}`)
			return shipmentData
		} catch (err) {
			throw new Error('Failed to fetch shipment: ' + err.message);
		}
	};


	useEffect(() => {
		const fetchData = async () => {
			try {
				if (!id) return;
				const shipmentData = await getShipmentById(id);
				if (!shipmentData) navigate('*')
				if (shipmentData?.ratings?.items?.length >= 1) setThanks(true)
				else {
					setShipment(shipmentData);
				}
			} catch (error) {
				handleApiError(error)
			}
			finally {
				showSpinner(false)
			}
		};

		fetchData();
	}, [id, navigate]);


	const handleBack = () => {
		setRating(null)
		setNPS(null)
		setShowNext(!showNext);
	};

	const handleNext = () => setShowNext(!showNext)

	const handleSubmit = async (e) => {
		e.preventDefault();
		const { id, shipperGroupId, shipperId } = shipment;
		setIsLoading(true);
		const body = {
			shipmentId: shipment?.id,
			rating: rating,
			nps: NPS,
			feedback: feedbackData,
			createdTime: moment().unix(),
			shipperGroupId: shipperGroupId || null,
			shipperId: shipperId || null,
		};

		if (id) {
			if (!shipperId || !shipperGroupId) {
				toast.error('Something went wrong....');
				setIsLoading(false);
				return;
			}
			try {
				API.post('api', '/public/shipment-feedback', { body }).then(() => {
					toast.success('Thanks, your feedback has been received successfully')
					setThanks(true);
					let message = `Shipment : <https://${process.env.REACT_APP_HQ_URL}/shipment/${shipment.id}|#${shipment?.number}>\n`;
					message += `Delivery: ${rating ? rating + '/5 Stars' : '- '}\n`
					message += `NPS: ${NPS ? NPS + '/10 Points' : '- '}\n`;
					message += `Comment: ${feedbackData.replace(/\n/g, '\n\t\t\t\t')}\n`;
					message += `Courier: ${shipment?.carrier?.name}\n`;
					message += `Driver: ${shipment?.driver?.name}\n`;
					message = message.replace(/(\w+) : /g, '$1: ');

					let user = `${shipment?.shipper?.name}`

					let channel;
					channel = rating > 3 ? 'feedback-positive' : 'feedback-negative'

					const carriers = ["fedex", "usps", "ups"];
					if (carriers.includes(shipment?.carrier?.name?.toLowerCase())) channel = 'feedback-carrier';

					const slackInput = { channel, message: message, user: user };
					API.graphql({ query: sendSlackAlert, variables: { input: slackInput } })
				})
			} catch (err) {
				toast.error(err);
			} finally {
				setIsLoading(false);
			}
		} else navigate('*')
	};


	return (
		<Spinner display={spinner}>
			{thanks ? <ThankYou /> :
				<section className='main-card-container d-flex'>
					<Container className='my-5'>
						<Row className='d-flex justify-content-center align-items-center h-100'>
							<Col lg={8} className='mx-auto'>
								<div className='card border-0'>
									<div className='card-body text-center ps-0 pe-0'>
										<img src={` ${shipment?.shipperGroup?.image ? shipment?.shipperGroup?.image : '/img/logo.svg'} `} alt='' className='img-fluid' style={{ width: '200px' }} />
										<hr />
										{!showNext ? (
											<div>
												<h1 className='px-4 py-2'>{`Thank you for using ${shipment?.shipper?.alias || 'Phox Health'}! How was your delivery experience?`}</h1>
												<h2 className='rating-mute-color display-3 p-2'>{rating ? ratingNames[rating - 1] : ''}</h2>
												{rating ? <div className='emoji-icons'>{RatingView}</div> : <img src='/img/feeling.png' alt='' className='img-fluid luminosity' />}
												<div>
													{[...Array(5)].map((_, index) => (
														<img src={rating <= index ? '/img/star.svg' : '/img/star-fill.svg'} key={index} className='cursor-pointer star-responsive me-2 m-3' alt='' onClick={() => handleRating(index)} />
													))}
												</div>
												<div className='form-group d-flex flex-column justify-content-center mx-4 ms-lg-5 me-lg-5'>
													<Form.Control required value={feedbackData} onChange={(e) => setFeedbackData(e.target.value)} as='textarea' placeholder={placeholder} rows='5' />
													<Button className='ms-auto btn bg-dark mt-2 btn-lg text-white btn-next' variant='dark' size='lg' onClick={handleNext}>Next</Button>
												</div>
											</div>
										) : (
											<div>
												<h1 className='px-5'>{`How likely are you to recommend ${shipment?.shipper?.alias || 'Phox Health'} to friends or family?`}</h1>
												{NPS ? <div className='emoji-icons'>{NPSView}</div> : <img src='/img/feeling.png' alt='' className='img-fluid luminosity' />}
												<Form onSubmit={handleSubmit}>
													<div className='page-item d-flex justify-content-center mt-5 number-rating'>
														{[...Array(10)].map((_, index) => {
															let item = index + 1;
															let color = '';
															if (item >= 1 && item <= 6) color = 'rating-red-border';
															else if (item >= 7 && item <= 8) color = 'rating-orange-border';
															else if (item >= 9 && item <= 10) color = 'rating-green-border';

															if (item <= NPS) {
																if (item >= 1 && item <= 6) color = 'rating-red text-white';
																else if (item >= 7 && item <= 8) color = 'rating-orange text-white';
																else if (item >= 9 && item <= 10) color = 'rating-green text-white';
															}
															return (
																<span key={index} className={`number-rating-one rating me-2 ${color} ${index + 1 === NPS ? 'selected' : ''}`} onClick={() => handleNPS(index)}>
																	{item}
																</span>
															);
														})}
													</div>

													<div className='mt-3 d-flex justify-content-around'>
														<span style={{ color: 'red' }}>1 = Not Likely</span>
														<span style={{ color: 'green' }} className='text-red'>10 = Extremely Likely</span>
													</div>

													<div className='form-group d-flex flex-column justify-content-center mx-4 ms-lg-5 me-lg-5 mt-3 mt-lg-5'>
														<Form.Control value={feedbackData} onChange={(e) => setFeedbackData(e.target.value)} as='textarea' placeholder={placeholder} rows='5' />
														<div className='mt-lg-3 mt-3 row'>
															<div className='col-6'>
																<Button className='btn btn-light text-dark w-100' size='lg' onClick={() => handleBack()}>Back</Button>
															</div>
															<div className='col-6'>
																<Button disabled={isLoading} className='btn btn-dark text-white w-100' size='lg' type='submit'>
																	{isLoading ? 'Loading' : 'Submit'}
																</Button>
															</div>
														</div>
													</div>
												</Form>
											</div>
										)}
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

			}
		</Spinner>
	)
}

const ThankYou = () => {
	const options = { animationData: celebration, loop: true };
	const { View } = useLottie(options);
	return (
		<section className='main-card-container d-flex'>
			<Container fluid>
				<Row className='d-flex flex-column justify-content-center align-items-center h-100'>
					<Col md={4} xs={8} className='p-0 rounded-top'>
						<img src={success} alt='' className='img-fluid rounded-top w-100' style={{ height: '25vw', objectFit: 'cover' }} />
					</Col>
					<Col md={4} xs={8} className='bg-white rounded-bottom text-center'>
						<div className='celebration-lottie w-25 mx-auto'>{View}</div>
						<p className='mt-4 responsive-heading'>Thank You</p>
						<p className='p-2'>Your feedback will help us to improve our service.</p>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default Feedback;

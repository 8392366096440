import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	HOST: {},
	USER: {},
	APP: {},
	SHIPPER_GROUP: {},
	SHIPPER_GROUPS: {},
	SHIPPER: {},
	SHIPPERS: [],
	CARRIER: {},
	CARRIERS: []
};

const storeInSession = (data) => localStorage.setItem('phsp', JSON.stringify(data))

const slice = createSlice({
	name: 'phsp',
	initialState,
	reducers: {
		storeHost(state, action) {
			state.HOST = action.payload;
			storeInSession(state);
		},
		storeUser(state, action) {
			state.USER = action.payload;
			storeInSession(state);
		},
		storeApp(state, action) {
			state.APP = action.payload;
			storeInSession(state);
		},
		storeShipperGroup(state, action) {
			state.SHIPPER_GROUP = action.payload
			storeInSession(state);
		},
		storeShipperGroups(state, action) {
			state.SHIPPER_GROUPS = action.payload
			storeInSession(state);
		},
		storeShipper(state, action) {
			state.SHIPPER = action.payload;
			storeInSession(state);
		},
		storeShippers(state, action) {
			state.SHIPPERS = action.payload;
			storeInSession(state);
		},
		storeCarrier(state, action) {
			state.CARRIER = action.payload;
			storeInSession(state);
		},
		storeCarriers(state, action) {
			state.CARRIERS = action.payload;
			storeInSession(state);
		}
	}
});

export const {
	storeHost,
	storeUser,
	storeApp,
	storeShipperGroup,
	storeShipperGroups,
	storeShipper,
	storeShippers,
	storeCarrier,
	storeCarriers
} = slice.actions;

export default slice.reducer;

import { Authenticator, Image, View } from '@aws-amplify/ui-react';
import { FullStory } from '@fullstory/browser';
import { API, Auth } from 'aws-amplify';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import ReactGA from "react-ga4";
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CommonHeader, getUserById } from '../../components/Auth';
import Spinner from '../../components/Spinner';
import { handleApiError, PAGE_TITLE } from '../../helpers';
import { storeShipper, storeShipperGroup, storeShipperGroups, storeShippers, storeUser } from '../../stores/slice';
import Host from './../../data/host.json';
import packageJson from "../../../package.json"

import '@aws-amplify/ui-react/styles.css';

export const createUserMutation = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
    }
  }
`;

export const updateUserMutation = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;

export const createUserLocationMutation = /* GraphQL */ `
  mutation CreateUserLocation($input: CreateUserLocationInput!) {
    createUserLocation(input: $input) {
      id
    }
  }
`;
const sendSlackAlert = /* GraphQL */ `
  mutation SendSlackAlert($input: SendSlackAlertInput!) {
    sendSlackAlert(input: $input)
  }
`;


const Login = () => {

	const QRCode = require("qrcode.react");

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/",
		})
		document.title = `Sign In ${PAGE_TITLE}`;
	}, [])

	console.log('host', window.location.host);

	let customProvider = '';
	if (Object.keys(Host).includes(window.location.host) && Host[window.location.host].customProvider) {
		customProvider = Host[window.location.host].customProvider;
	}


	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [image, setImage] = useState('/img/login-cover-new.png')
	const [spinner, showSpinner] = useState(false);
	const [user, setUser] = useState();
	const [mfaModal, showMFAModal] = useState(false);
	const [mfaSecretKey, setMfaSecretKey] = useState('')
	const [otp, setOTP] = useState('');

	const components = {
		SignIn: { Header: () => <CommonHeader title='Sign In' subtitle='The Tech-Enabled Solution for Healthcare Logistics' setImage={setImage} /> },
		SignUp: { Header: () => null },
		ConfirmSignUp: { Header: () => null },
		SetupTOTP: { Header: () => null },
		ConfirmSignIn: { Header: () => <CommonHeader title='Confirm Sign In' subtitle='Please use the verification code sent to your email to reset your password' setImage={setImage} /> },
		ResetPassword: { Header: () => <CommonHeader title='Forgot Password' subtitle='Enter your email to continue' setImage={setImage} /> },
		ConfirmResetPassword: { Header: () => <CommonHeader title='Forgot Password' subtitle='Please use the verification code sent to your email to reset your password' setImage={setImage} /> },
	};

	const adLogin = () => {
		showSpinner(true);
		Auth.federatedSignIn({ customProvider: customProvider })
	}

	useEffect(() => {
		showSpinner(true);

		Auth.currentAuthenticatedUser().then(async (user) => {
			setUser(user);
		}).catch((error) => {
			console.error(error);
		}).finally(() => {
			showSpinner(false);
		});

	}, []);

	// const postLogin = async (id) => {
	// 	try {
	// 		showSpinner(true);
	// 		const data = await API.graphql({ query: getUserById, variables: { id: id } });
	// 		const user = data?.data?.getUser;
	// 		let localShipperData = localStorage.getItem('phsp')
	// 		if (user?.active === true && user?.shippers?.items.length > 0) {
	// 			const shippers = user.shippers.items.filter(x => x.shipper?.id).sort((a, b) => a.shipper?.name?.localeCompare(b.shipper?.name, undefined, { sensitivity: 'accent' }))
	// 			dispatch(storeUser(user));
	// 			dispatch(storeShippers(shippers));
	// 			if (localShipperData) {
	// 				localShipperData = JSON.parse(localShipperData);
	// 				let defaultShipper = shippers?.findIndex((item) => item?.shipper?.id === localShipperData?.SHIPPER?.shipper?.id)
	// 				if (defaultShipper !== -1) {
	// 					dispatch(storeShipper(shippers[defaultShipper]));
	// 				} else {
	// 					dispatch(storeShipper(shippers[0]));
	// 				}
	// 			} else {
	// 				dispatch(storeShipper(shippers[0]));
	// 			}
	// 			localStorage.setItem('lastActiveSP', Date.now().toString())
	// 			navigate('/dashboard');
	// 		} else {
	// 			toast.error(`Access Denied`);
	// 			await Auth.signOut();
	// 			showSpinner(false);
	// 		}
	// 	} catch (error) {
	// 		console.error('An error occurred:', error);
	// 		showSpinner(false);
	// 	}
	// };

	useEffect(() => {
		handleCheckUserMFA()
	}, [user]);

	const handleCheckUserMFA = async () => {
		if (!user?.signInUserSession?.idToken?.payload?.identitie && user?.preferredMFA === 'NOMFA') {
			try {
				const totpSetupDetails = await Auth.setupTOTP(user);
				setMfaSecretKey(totpSetupDetails);
				showMFAModal(true)
			} catch (error) {
				showSpinner(false);
				console.error("Error setting up TOTP:", error);
			}
		} else {
			handleUserSignIn();
		}
	}

	const handleConfirmSignIn = async () => {
		if (!otp.trim()) return toast.error('Please enter a valid code!')
		if (otp?.length < 6) return toast.error('Please enter a code with 6 or more characters.')
		showSpinner(true)
		try {
			const cognitoUserSession = await Auth.verifyTotpToken(user, otp);
			await Auth.setPreferredMFA(user, 'TOTP').then(async () => {
				toast.success('Setup completed successfully')
				await handleUserSignIn();
			});
		} catch (error) {
			showSpinner(false)
			if (error == 'EnableSoftwareTokenMFAException: Code mismatch') {
				toast.error('Invalid code. Please check and try again.')
			} else {
				return handleApiError(error);
			}
		}
	}


	const handleUserSignIn = async () => {
		showSpinner(true);
		let loginProvider = 'Email';
		if (user?.username) {
			if (user.signInUserSession.idToken.payload.identities) {
				loginProvider = user.signInUserSession.idToken.payload.identities[0].providerName;
			}

			API.graphql({ query: getUserById, variables: { id: user.signInUserSession.idToken.payload.sub } }).then((data) => {
				const dbUser = data?.data?.getUser;
				if (dbUser) {
					if (dbUser.active === true && dbUser?.shippers?.items.length > 0) {
						let name = '';
						if (user.attributes?.name) name = user.attributes.name || 'User';
						if (user.signInUserSession.idToken.payload.given_name) name = `${user.signInUserSession.idToken.payload.given_name || ''} ${user.signInUserSession.idToken.payload.family_name || ''}`;

						API.graphql({
							query: updateUserMutation,
							variables: {
								input: {
									id: user.signInUserSession.idToken.payload.sub,
									// name: name,
									loginProvider: loginProvider,
									loginAt: moment().unix()
								}
							}
						}).then(() => {
							const shippers = dbUser.shippers.items.filter(x => x.shipper?.id).sort((a, b) => a.shipper?.name?.localeCompare(b.shipper?.name, undefined, { sensitivity: 'accent' }))
							const shipperGroup = dbUser.shipperGroups.items.filter(x => x.shipperGroup?.id).sort((a, b) => a.shipperGroup?.name?.localeCompare(b.shipperGroup?.name, undefined, { sensitivity: 'accent' }))
							dispatch(storeUser(dbUser));
							!shipperGroup[0] && dispatch(storeShipper(shippers[0]))
							dispatch(storeShippers(shippers));
							dispatch(storeShipperGroups(shipperGroup));
							dispatch(storeShipperGroup(shipperGroup[0]));
							FullStory('setIdentity', {
								uid: dbUser?.id,
								properties: {
									displayName: dbUser?.name,
									email: dbUser?.email,
									shipper: shippers[0]?.shipper?.name || shippers[0]?.shipper?.alias,
									role: shippers[0]?.role,
								}
							})
							navigate('/today');
						}).catch(error => {
							handleApiError(error)
						})
					} else {
						toast('We are reviewing your account and will get back to you in 24-48 hours.')
					}
				} else {
					const createUser = API.graphql({
						query: createUserMutation,
						variables: {
							input: {
								id: user.signInUserSession.idToken.payload.sub,
								name: `${user.signInUserSession.idToken.payload.given_name} ${user.signInUserSession.idToken.payload.family_name}`,
								email: user.signInUserSession.idToken.payload.email,
								userLocationId: user.signInUserSession.idToken.payload.sub,
								loginProvider: loginProvider,
								active: false,
							}
						},
					});

					const createUserLocation = API.graphql({
						query: createUserLocationMutation,
						variables: {
							input: {
								id: user.signInUserSession.idToken.payload.sub
							}
						},
					});

					Promise.all([createUser, createUserLocation]).then(async (res) => {
						try {
							let responseId = res[0]?.data?.createUser?.id;
							const slackInput = {
								channel: 'signups',
								message: ` <https://${process.env.REACT_APP_HQ_URL}/user/${responseId}/overview|${user.signInUserSession.idToken.payload.given_name} ${user.signInUserSession.idToken.payload.family_name}> (${user.signInUserSession.idToken.payload.email}) has signed from shipper portal, please review.`,
								user: user.signInUserSession.idToken.payload.email
							}
							await API.graphql({ query: sendSlackAlert, variables: { input: slackInput } })
						} catch (error) {
							handleApiError(error);
						}
						toast('We are reviewing your account and will get back to you in 24-48 hours.')
					}).catch((error) => {
						handleApiError(error)
					})
				}

			}).catch((error) => {
				handleApiError(error)
			})
		}
	}

	return (
		<Row className='d-flex'>
			<Col lg={12} xl={6} className='card-container my-auto'>
				<Card className='login-card-container mx-auto my-auto'>
					<View textAlign='center' padding='1.5rem 0rem'>
						<Image alt='Phox logo' src='/img/logo.svg' className='w-50' />
					</View>

					<Spinner display={spinner}>
						{
							mfaModal ? <>
								<div className="d-flex justify-content-center align-items-center p-2" style={{ fontSize: '12px' }}>
									<div className="" style={{ width: "400px" }}>
										<div className="">
											<h2 className="card-title text-center mb-4">Set up MFA</h2>
											<div className="mb-2">
												<p className='fw-bold mb-1'>1. Download an Authenticator App</p>
												<p>If you don't already have one, download an authenticator app like Google Authenticator or Authy from your app store.</p>
											</div>
											<hr className='my-2' />
											<div className="mb-1">
												<p className='fw-bold mb-1'>2. Scan this QR Code with Your Authenticator App.</p>
												<p className='mb-1'> Scan the QR Code: Open the authenticator app, choose "Add Account" and scan the QR code displayed on this screen. </p>
												<div className='d-flex justify-content-center align-item-center '>
													<p className='my-0'>OR</p>
												</div>
												<p className='mt-0'> Enter the Key Manually: If you can't scan the QR code, copy the key below and enter it into the app:</p>
												<div className="d-flex justify-content-center mb-2">
													<div style={{ width: "120px", height: "120px", }}>
														<div className="text-center">
															<QRCode.QRCodeSVG value={"otpauth://totp/PhoxOS:" + user?.attributes?.email + "?secret=" + mfaSecretKey + "&issuer=" + 'Phox Health'} size={120} />
														</div>
													</div>
												</div>
												<div className="text-center my-1">
													<p className="text-primary my-1 cursor-pointer" style={{ fontSize: "0.9rem" }} onClick={() => navigator.clipboard.writeText(mfaSecretKey).then(() => { toast.success('copied') })}>
														Copy Key
													</p>
												</div>
											</div>
											<hr className='my-2' />
											<div className="mb-1">
												<p className='fw-bold mb-1'> 3. Enter the Code Displayed on Your Authenticator App.</p>
												<p className='mb-2'>After scanning or entering the key, the app will generate a verification code. Enter the code below to verify your setup.</p>
												<div className='px-3'>
													<input
														value={otp}
														style={{ height: '28px' }}
														type="text"
														className="form-control mb-2"
														placeholder="Enter code"
														onChange={(e) => setOTP(e?.target?.value?.trim())}
													/>
												</div>
											</div>

											<div className="d-flex flex-column px-3">
												<button className="btn btn-dark btn-sm mb-2" onClick={() => handleConfirmSignIn()}>Continue</button>
												<button className="btn btn-light mb-2 btn-sm" onClick={() => handleUserSignIn()} >Skip for now</button>
											</div>
										</div>
									</div>
								</div>

							</> : <>
								{
									user?.signInUserSession?.idToken?.payload?.sub ? <div className='text-center'>
										<h1 className='text-center'>
											{`Hi ${user.signInUserSession.idToken.payload.name || user.signInUserSession.idToken.payload.given_name || 'User'}`}
										</h1>
										<p>
											Please wait while we setup your session
										</p>
										<Spinner display={true} />
									</div> : <div>
										{
											customProvider ?
												<div className='m-4'>
													<button className='btn btn-dark w-100' onClick={() => adLogin()}>Sign In with {Host[window.location.host].customProviderName}</button>
												</div> : <Authenticator hideSignUp={true} components={components} loginMechanisms={['email']} socialProviders={[]} >
													{
														({ user }) => {
															if (user) setUser(user);
														}
													}
												</Authenticator>
										}
									</div>
								}
							</>
						}
					</Spinner>
					<View textAlign='center' style={{ color: "#666666" }}>
						{packageJson?.version &&
							<p className='fs-4'>v{packageJson?.version}</p>
						}
					</View>
				</Card>
			</Col>
			<Col xl={6} className='my-auto mx-auto login-cover d-none d-xl-block bg-cover'>
				<img src={image} alt='Login-cover' className='login-cover-img img-fluid w-75' />
			</Col>
		</Row>
	)
}

export default Login;
/* eslint-disable react-hooks/exhaustive-deps */
import { API } from "aws-amplify";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Container, Row, Table } from "react-bootstrap";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ReactGA from "react-ga4";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import uniqid from 'uniqid';
import PageHeader from "../../components/PageHeader";
import Spinner from "../../components/Spinner";
import { StatusBadge } from "../../components/StatusBadge";
import {
    TableClearFilter,
    TableKeywordSearch,
    TablePagination,
    TableShipperFilter
} from "../../components/TableFilter";
import {
    DELAY_TIMEOUT,
    handleApiError,
    PAGE_SIZE,
    toLocalDate,
    toLocalTime
} from "../../helpers";


export const createEndiciaManifest = /* GraphQL */ `
  mutation CreateEndiciaManifest($id: ID!) {
    createEndiciaManifest(id: $id)
  }
`;

export const createShipmentManifest = /* GraphQL */ `
  mutation CreateShipmentManifest(
    $input: CreateShipmentManifestInput!
    $condition: ModelShipmentManifestConditionInput
  ) {
    createShipmentManifest(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateShipment = /* GraphQL */ `
  mutation UpdateShipment(
    $input: UpdateShipmentInput!
    $condition: ModelShipmentConditionInput
  ) {
    updateShipment(input: $input, condition: $condition) {
      id
    }
  }
`;
const ShipmentManifestCreate = () => {
    let pageSize = 1000;
    const myShipper = useSelector((state) => state.slice.SHIPPER);
    const myShippers = useSelector((state) => state.slice.SHIPPERS);

    const navigate = useNavigate()

    const [spinner, showSpinner] = useState(true);
    const [shipments, setShipments] = useState([]);
    const [facets, setFacets] = useState({});

    const [keyword, setKeyword] = useState("");

    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();

    const [checkedAll, setCheckedAll] = useState(false)
    const [selectCount, setSelectCount] = useState(0)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)

    const [dateFilters, setDateFilters] = useState({
        fromDate: moment()
            .tz(myShipper?.shipper?.timezone?.id || "America/New_York")
            .startOf("day")
            .unix(),
        toDate: moment()
            .tz(myShipper?.shipper?.timezone?.id || "America/New_York")
            .endOf("day")
            .unix(),
    });

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/shipment",
        })
    }, [])

    useEffect(() => {
        if (!checkedAll) setSelectCount(shipments?.filter((item) => item?.checked === true).length)
    }, [shipments])


    useEffect(() => {
        const delay = setTimeout(() => {
            getElasticShipment();
        }, DELAY_TIMEOUT);
        return () => clearTimeout(delay);
    }, [keyword, dateFilters, myShipper, pageNumber]);

    const apiName = 'api';
    let init = {
        body: {
            aggs: {
                status: {
                    terms: {
                        "field": "status.keyword",
                        "size": 100
                    }
                }
            },
            sort: [
                { "_score": { "order": "desc" } },
                {
                    "createdTime": {
                        "order": "desc",
                        "unmapped_type": "date"
                    }
                }
            ],
            query: {
                bool: {
                    must: [{ match: { status: 'READY_FOR_PICKUP' } }, {
                        match: { "carrier.alias.keyword": "USPS" }
                    }],
                    must_not: [{ exists: { field: "manifestId" } },
                    { match: { "deliveryService.name.keyword": "First-Class Mail" } }]
                }
            }
        }
    };
    if (!keyword) {
        init.body.query.bool.must.push({
            range: {
                "createdTime": {
                    "gte": dateFilters?.fromDate,
                    "lte": dateFilters?.toDate
                }
            }
        })
    }

    if (keyword) {
        let fields = [
            "shipTo.name^3",
            "shipTo.first_name^2",
            "shipTo.last_name",
            "shipTo.phone",
            "extId",
            "customer.extId",
            "shipTo.address.address1",
            "shipTo.address.postalCode"
        ]
        if (+keyword) {
            fields.push("number")
        }
        init.body.query.bool.must.push(
            {
                "multi_match": {
                    "query": keyword,
                    "fields": fields
                }
            }
        )
        if (keyword.includes("-")) { // RX number includes '-'
            init.body.query.bool.must.pop()
            init.body.query.bool.filter = {
                "terms": {
                    "items.number.keyword": [keyword]
                }
            }
        }
    }

    if (myShipper?.shipper?.id) init.body.query.bool.must.push({ match: { shipperId: myShipper?.shipper?.id } })
    else if (myShippers) {
        let shipperIdArr = []
        myShippers.forEach((item) => {
            shipperIdArr.push(item.shipper.id)
        })
        init.body.query.bool.must.push({ "terms": { "shipperId": shipperIdArr } })
    }

    const getElasticShipment = async () => {
        showSpinner(true);

        try {
            const path = `/search/shipment?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
            const data = await API.post(apiName, path, init);

            let statusCount = {};
            data.aggregations.status.buckets?.forEach((item) => {
                statusCount[item.key] = item.doc_count
            })

            setFacets(data.aggregations)
            setTotal(data.hits.total.value)
            if (checkedAll) setSelectCount(data.hits.total.value)
            let sourceData = data?.hits?.hits?.map((item) => {
                return { ...item?._source, checked: checkedAll ? true : false }
            }) || []
            setShipments(sourceData);
            setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));
            showSpinner(false);
        } catch (error) {
            handleApiError(error)
            showSpinner(false);
        }
    }

    const clearFilters = () => {
        setKeyword("");
        setPageNumber(0);
    };
    function isValidURL(string) {
        try {
            return new URL(string);
        } catch (err) {
            return false;
        }
    }
    const bulkShipmentUpdate = async (manifestId) => {
        try {
            let ids = []

            if (checkedAll) {
                const path = `/search/shipment?size=${0}&from=${0}`;
                let data = await API.post(apiName, path, init);
                let page = 0;
                let apiArgs = []
                while (page < Math.ceil(data.hits.total.value / pageSize)) {
                    const path = `/search/shipment?size=${pageSize}&from=${page * pageSize}`;
                    apiArgs.push({ apiName, path, init });
                    page++;
                }

                let promises = apiArgs.map(call => API.post(call.apiName, call.path, call.init));
                let res = await Promise.all(promises)
                let items = []
                for (let item of res) {
                    const sourceData = item?.hits?.hits?.map((item) => item?._source)
                    items.push(...sourceData)
                }
                ids = items.map((item) => item.id)

            } else ids = shipments.filter(item => item.checked === true).map((item => item.id))
            let promises = []
            for (let item of ids) {
                promises.push({ query: updateShipment, variables: { input: { id: item, manifestId } } })
            }
            let batchPromises = []
            let l = 0;
            while (l < promises.length) {
                let temp = []
                for (let i = 0; i < 200 && l < promises.length; i++) {
                    temp.push(promises[l])
                    l++
                }
                batchPromises.push(temp)
            }
            let i = 0;
            while (i < batchPromises.length) {
                batchPromises[i] = batchPromises[i].map((item) => API.graphql(item))
                await Promise.all(batchPromises[i]).then(i++)
            }
            let address = shipments[0]?.shipFrom.address
            let from_address = {
                "company_name": myShipper?.shipper?.name,
                "name": myShipper?.shipper?.name,
                "address_line1": address?.address1,
                "address_line2": address?.address2,
                "address_line3": address?.address3,
                "city": address?.city,
                "state_province": address?.state,
                "postal_code": address?.postalCode,
                "country_code": address?.country,
                "phone": null,
                "email": null
            }
            let res = await API.graphql({ query: createEndiciaManifest, variables: { id: manifestId, from_address } })
            let url = JSON.parse(res.data.createEndiciaManifest)?.data

            if (isValidURL(url)) window.open(url)
            else toast.error("Something went wrong.Please refresh!")
            setCheckedAll(false)
            setShipments((prev) =>
                prev.map((item) => {
                    return { ...item, checked: false }
                })
            )

            showSpinner(false)
        } catch (error) {
            setShipments((prev) =>
                prev.map((item) => {
                    return { ...item, checked: false }
                })
            )
            handleApiError(error)
            showSpinner(false);
        }
    };
    async function createManifest() {
        try {
            if (selectCount == 0) return toast.error("Please select atleast one shipment !")
            swal(`Are you sure you want generate manifest for ${selectCount} ${selectCount > 1 ? 'shipments' : 'shipment'}?`, {
                buttons: ["No", "Yes"],
            }).then(async (res) => {
                if (res === true) {
                    setLoading(true)
                    let loader = toast.loading("Generating Manifest...")
                    const manifestId = uniqid();
                    // create manifest entry

                    await API.graphql({ query: createShipmentManifest, variables: { input: { id: manifestId, shipperId: myShipper?.shipper?.id, shipperGroupId: myShipper?.shipper?.shipperGroupId, createdTime: moment().unix(), count: selectCount } } })
                    // update shipments
                    await bulkShipmentUpdate(manifestId)
                    setLoading(false)
                    toast.dismiss(loader)
                }
            })
        } catch (error) {
            handleApiError(error)
            setLoading(false)
        }
    }

    const handleDateChange = (event, picker) => {
        const timezone = myShipper?.shipper?.timezone?.id || "America/New_York"
        setDateFilters({
            fromDate: moment.tz(picker.startDate.format('YYYY-MM-DD 00:00:00'), timezone).unix(),
            toDate: moment.tz(picker.startDate.format('YYYY-MM-DD 23:59:59'), timezone).unix(),
        })
    }

    return (
        <>
            <PageHeader name="Create Manifest">
                <button className="btn btn-dark" onClick={() => navigate("/shipment-manifest")}>View Manifest</button>
            </PageHeader>
            <Container fluid>
                <Card>
                    <Card.Header>
                        <Row>
                            <TableKeywordSearch keyword={keyword} onChange={setKeyword} />
                            {(selectCount || shipments?.filter((item) => item?.checked === true).length > 0) && (
                                <div className='col-auto d-flex align-items-center'>
                                    <h5 className='m-0'>{selectCount} Selected</h5>
                                </div>
                            )}
                            <div className='col-auto mt-1'>
                                <DateRangePicker onApply={handleDateChange}
                                    initialSettings={{
                                        startDate: moment(),
                                        endDate: moment(),
                                        singleDatePicker: true,
                                        showDropdowns: true,
                                        alwaysShowCalendars: true,
                                        opens: 'left',
                                        locale: { format: 'MMM D, YYYY' }
                                    }}>
                                    <input className='btn btn-sm btn-light' />
                                </DateRangePicker>
                            </div>
                            <TableShipperFilter hideAll={true} />
                            <div className="col-auto mt-1">
                                <button onClick={createManifest} disabled={loading} className="btn btn-sm m-0 btn-dark">Create Manifest</button>
                            </div>
                            <TableClearFilter onClick={clearFilters} />
                        </Row>
                    </Card.Header>
                    <Spinner display={spinner}>
                        <Table responsive size="sm" className="mb-0">
                            <thead>
                                <tr>
                                    <th><input type="checkbox" checked={checkedAll} className='form-check-input' onChange={(e) => {
                                        setCheckedAll(e.target.checked)
                                        setSelectCount(e.target.checked ? total : 0)
                                        let temp = structuredClone(shipments)
                                        for (let item of temp) {
                                            item.checked = e.target.checked
                                        }
                                        setShipments(temp)
                                    }} /></th>
                                    <th className="text-center">Shipment #</th>
                                    {!myShipper?.shipper?.id && <th>Shipper</th>}
                                    <th>Recipient</th>
                                    <th>Driver / Carrier</th>
                                    <th className="text-center">Delivery Service</th>
                                    <th className="text-center">Entered At</th>
                                    <th className="text-center">Due By</th>
                                    <th className="text-center">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shipments.map((shipment) => {
                                    return (
                                        <tr key={shipment.id}>
                                            <td onClick={(e) => e.stopPropagation()}><input type="checkbox" checked={shipment?.checked === true} className='form-check-input' onChange={(e) => {
                                                setCheckedAll(false)
                                                setShipments((prev) =>
                                                    prev.map((item) =>
                                                        item.id === shipment.id ? { ...item, checked: e.target.checked } : item
                                                    )
                                                )
                                            }} /></td>
                                            <td className="text-center">
                                                <div>
                                                    {shipment?.isGreenPhox ? <img src="img/greenphox.svg" alt="green-phox-icon rounded-circle" className="mx-2" height={'20px'} width={'20px'} /> : <small className="ms-5"> </small>}
                                                    {shipment?.number}
                                                </div>
                                                {shipment.batch_number > 0 && (
                                                    <div className="small text-muted">
                                                        Batch #{shipment?.batch_number}
                                                    </div>
                                                )}
                                            </td>
                                            {!myShipper?.shipper?.id && (
                                                <td>{shipment?.shipper?.name}</td>
                                            )}
                                            <td>
                                                {shipment?.shipTo?.name}
                                                <div className="small text-muted">
                                                    {shipment?.shipTo?.address?.city},{" "}
                                                    {shipment?.shipTo?.address?.state}
                                                </div>
                                            </td>
                                            <td>
                                                <div>{shipment?.driver?.name || "Unassigned"}</div>
                                                <div className="text-small text-muted">
                                                    {shipment?.carrier?.alias}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                {shipment?.deliveryService?.name}
                                            </td>
                                            <td className="text-center">
                                                {toLocalTime(
                                                    shipment?.createdTime,
                                                    shipment?._source?.shipFrom?.timezone?.id
                                                )}
                                                <div className="small text-muted">
                                                    {toLocalDate(
                                                        shipment?.createdTime,
                                                        shipment?.shipFrom?.timezone?.id
                                                    )}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                {shipment?.expectedDeliveryTime && (
                                                    <>
                                                        {toLocalTime(
                                                            shipment?.expectedDeliveryTime,
                                                            shipment?.shipFrom?.timezone?.id
                                                        )}
                                                        <div className="small text-muted">
                                                            {toLocalDate(
                                                                shipment?.expectedDeliveryTime,
                                                                shipment?.shipFrom?.timezone?.id
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </td>
                                            <td className="text-center">
                                                <StatusBadge status={shipment?.status} />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <TablePagination
                                pageNumber={pageNumber}
                                pageCount={pageCount}
                                setPageNumber={setPageNumber}
                                colSpan={8}
                            />
                        </Table>
                    </Spinner>
                </Card>
            </Container>
        </>
    );
};

export default ShipmentManifestCreate;

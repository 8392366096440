import { API } from "aws-amplify";
import moment from "moment";
import { useEffect, useState } from "react";
import { Card, Col, Container, ListGroup, ListGroupItem, Row, Table } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import StarRatings from 'react-star-ratings';
import { StatCard } from "../../components/Card";
import { ElasticFacetFilter } from "../../components/ElasticFilter";
import PageHeader from "../../components/PageHeader";
import Spinner from "../../components/Spinner";
import { TableClearFilter, TableShipperFilter, TableShipperGroupFilter, TimeZoneFilter } from "../../components/TableFilter";
import { DELAY_TIMEOUT, handleApiError } from "../../helpers";
import { storeShipper } from "../../stores/slice";
import { DoughnutChart } from "../../components/Chart";
import { useOutletContext } from "react-router-dom";


const TodayDashboard = () => {
    const { fullScreen, handleFullScreen } = useOutletContext();
    let intialStatus = {
        totalDeliveries: 0,
        readyForPickUp: 0,
        outForDelivery: 0,
        delivered: 0,
        deliveredPrecentage: 0,
        outForDeliveryPercentage: 0,
        readyForPickUpPercentage: 0,
        totalDeliveredPercentage: 0,
        totalPendingPercentage: 0,
        missed: 0,
        returned: 0,
        returnedPercentage: 0
    }

    let intialAvgData = { avgNps: 0, avgRating: 0, totalRatingCount: 0, totalNpsCount: 0 };
    let intialDeliveryStatus = { onTime: 0, delayed: 0, ontimePercentage: 0, delayedPercentage: 0 };
    let intialNpsRating = [
        { label: 'Promoters', color: '#00A76F', percentage: 0, count: 0 },
        { label: 'Passives', color: '#FEC203', percentage: 0, count: 0 },
        { label: 'Detractors', color: '#E84521', percentage: 0, count: 0 },
    ]

    const dispatch = useDispatch();

    const myShipper = useSelector((state) => state.slice.SHIPPER);
    const myShippers = useSelector((state) => state.slice.SHIPPERS);
    const myShipperGroup = useSelector((state) => state.slice.SHIPPER_GROUP);
    const [carrier, setCarrier] = useState();
    const [statusData, setStatusData] = useState(intialStatus);
    const [spinner, showSpinner] = useState(false);
    const [timeZone, setTimeZone] = useState(myShipperGroup?.shipperGroup?.timezone?.id || "America/New_York");
    const [locationData, setLocationData] = useState([]);
    const [facets, setFacets] = useState({})
    const [avgData, setAvgData] = useState(intialAvgData);
    const [feedback, setFeedback] = useState([]);
    const [deliveryStatus, setDeliveryStatus] = useState(intialDeliveryStatus)
    const [tz, setTz] = useState(myShipperGroup?.shipperGroup?.timezone?.alias || "EST");
    const [starRating, setStarRating] = useState([]);
    const [npsRating, setNpsRating] = useState(intialNpsRating)


    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/today",
        })
    }, [])

    useEffect(() => {
        const delay = setTimeout(() => { getElasticData(); }, DELAY_TIMEOUT);

        let interval;
        if (fullScreen) {
            interval = setInterval(() => {
                getElasticData();
            }, 300000);
        }

        return () => {
            clearTimeout(delay)
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [myShipper, carrier, myShippers, myShipperGroup, timeZone])


    useEffect(() => {
        if (myShipper?.shipper?.timezone?.id) {
            setTimeZone(myShipper?.shipper?.timezone?.id)
            setTz(myShipper?.shipper?.timezone?.alias)
        } else if (myShipperGroup?.shipperGroup?.timezone?.id) {
            setTimeZone(myShipperGroup?.shipperGroup?.timezone?.id)
            setTz(myShipperGroup?.shipperGroup?.timezone?.alias)
        }
    }, [myShipperGroup?.shipperGroup?.timezone.id, myShipper?.shipper?.timezone?.id])

    const getElasticData = async () => {
        showSpinner(true)
        try {
            const apiName = 'api';
            const path = `/search/shipment`;

            const init = {
                body: {
                    "aggs": {
                        "filtered_shipments": {
                            "filter": {
                                "bool": {
                                    "must_not": [
                                        { "terms": { "status.keyword": ["OPEN", "EXCEPTION"] } }
                                    ]
                                }
                            },
                            "aggs": {
                                "total_shipments": {
                                    "value_count": {
                                        "field": "expectedDeliveryTime"
                                    }
                                }
                            }
                        },
                        "ratings_by_star": {
                            "terms": {
                                "field": "ratings.items.rating",
                                "size": 5,
                                "order": { "_key": "desc" },
                                "min_doc_count": 0
                            },
                            "aggs": {
                                "rating_count": {
                                    "value_count": {
                                        "field": "ratings.items.rating"
                                    }
                                }
                            }

                        },
                        "nps_stats": {
                            "scripted_metric": {
                                "init_script": "state.promoters = 0; state.passives = 0; state.detractors = 0; state.total = 0;",
                                "map_script": `
									if (doc.containsKey('ratings.items.nps') && doc['ratings.items.nps'].size() > 0) {
										def nps = doc['ratings.items.nps'].value;
										state.total += 1;
										
										if (nps > 8) {
										state.promoters += 1;
										} else if (nps >= 7 && nps <= 8) {
										state.passives += 1;
										} else {
										state.detractors += 1;
										}
									}
								`,
                                "combine_script": "return state;",
                                "reduce_script": `
								int promoters = 0;
								int passives = 0;
								int detractors = 0;
								int total = 0;

								for (s in states) {
									promoters += s.promoters;
									passives += s.passives;
									detractors += s.detractors;
									total += s.total;
								}

								if (total > 0) {
									double promotersPercentage = (promoters / (double) total) * 100;
									double passivesPercentage = (passives / (double) total) * 100;
									double detractorsPercentage = (detractors / (double) total) * 100;

									double npsScore = Math.round((Math.round(promotersPercentage)) - (Math.round(detractorsPercentage)));

									return [
									"promoters": promoters,
									"promotersPercentage": promotersPercentage,
									"passives": passives,
									"passivesPercentage": passivesPercentage,
									"detractors": detractors,
									"detractorsPercentage": detractorsPercentage,
									"npsScore": npsScore,
									"total": total
									];
								} else {
									return [
									"promoters": promoters,
									"promotersPercentage": 0,
									"passives": passives,
									"passivesPercentage": 0,
									"detractors": detractors,
									"detractorsPercentage": 0,
									"npsScore": 0,
									"total": total
									];
								}
								`
                            }
                        },
                        "status_breakdown": {
                            "terms": {
                                "field": "status.keyword",
                                "order": {
                                    "_key": "desc"
                                },
                                "min_doc_count": 0
                            },
                            "aggs": {
                                "count": {
                                    "value_count": {
                                        "field": "status.keyword"
                                    }
                                }
                            }
                        },
                        "total_rating_count": {
                            "value_count": {
                                "field": "ratings.items.rating"
                            }
                        },
                        "total_nps_count": {
                            "value_count": {
                                "field": "ratings.items.nps"
                            }
                        },
                        "avg_rating": {
                            "avg": {
                                "field": "ratings.items.rating"
                            }
                        },
                        "avg_nps": {
                            "scripted_metric": {
                                "init_script": "state.promoters = 0; state.detractors = 0; state.total = 0;",
                                "map_script": `
                                    if (doc['ratings.items.nps'].size() > 0) {
                                        def nps = doc['ratings.items.nps'].value;
                                        if (nps >= 9) {
                                            state.promoters += 1;
                                        } else if (nps <= 6) {
                                            state.detractors += 1;
                                        }
                                        state.total += 1;
                                    }
                                `,
                                "combine_script": "return state;",
                                "reduce_script": `
                                    int promoters = 0;
                                    int detractors = 0;
                                    int total = 0;
                                    
                                    for (s in states) {
                                        promoters += s.promoters;
                                        detractors += s.detractors;
                                        total += s.total;
                                    }
                                    
                                    double promotersPercentage = 0.0;
                                    double detractorsPercentage = 0.0;
                                    double result = 0.0;
                                    
                                    if (total > 0) {
                                        promotersPercentage = (promoters / (double) total) * 100;
                                        detractorsPercentage = (detractors / (double) total) * 100;
                                        result = Math.round(promotersPercentage) - Math.round(detractorsPercentage);
                                    }
                                    
                                    return (int) Math.round(result)
                                `
                            }
                        },
                        "delivery_status_count": {
                            "terms": {
                                "script": {
                                    "source": `
                                    if (doc['status.keyword'].size() > 0 && doc['status.keyword'].value == 'DELIVERED') {
                                        if (doc['expectedDeliveryTime'].size() > 0 && doc['actualDeliveryTime'].size() > 0) {
                                            return doc['actualDeliveryTime'].value <= doc['expectedDeliveryTime'].value ? 'ontime' : 'late';
                                        } else {
                                            return 'unknown';
                                        }
                                    } else {
                                        return 'not_delivered';
                                    }
                                `,
                                    "lang": "painless"
                                }
                            },
                            "aggs": {
                                "count": {
                                    "value_count": {
                                        "field": "expectedDeliveryTime"
                                    }
                                }
                            }
                        },
                        "location": {
                            "terms": {
                                "field": "shipper.name.keyword",
                                "size": 100,
                                "order": {
                                    "_key": "asc"
                                },
                                "min_doc_count": 0
                            },
                            "aggs": {
                                "total_shipments_count": {
                                    "filter": {
                                        "bool": {
                                            "must_not": [
                                                {
                                                    "terms": {
                                                        "status.keyword": [
                                                            "OPEN",
                                                            "EXCEPTION"
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    "aggs": {
                                        "count": {
                                            "value_count": {
                                                "field": "id.keyword"
                                            }
                                        }
                                    }
                                },
                                "avg_rating": {
                                    "avg": {
                                        "field": "ratings.items.rating"
                                    }
                                },
                                "avg_nps": {
                                    "scripted_metric": {
                                        "init_script": "state.promoters = 0; state.detractors = 0; state.total = 0;",
                                        "map_script": `
											if (doc['ratings.items.nps'].size() > 0) {
											  def nps = doc['ratings.items.nps'].value;
											  if (nps >= 9) {
												state.promoters += 1;
											  } else if (nps <= 6) {
												state.detractors += 1;
											  }
											  state.total += 1;
											}
										  `,
                                        "combine_script": "return state;",
                                        "reduce_script": `
											int promoters = 0;
											int detractors = 0;
											int total = 0;
											
											for (s in states) {
											  promoters += s.promoters;
											  detractors += s.detractors;
											  total += s.total;
											}
											
											double promotersPercentage = 0.0;
											double detractorsPercentage = 0.0;
											double result = 0.0;
											
											if (total > 0) {
												promotersPercentage = (promoters / (double) total) * 100;
												detractorsPercentage = (detractors / (double) total) * 100;
												result = Math.round(promotersPercentage) - Math.round(detractorsPercentage);
											}
											
											return (int) Math.round(result)
										  `
                                    }
                                },
                                "total_ratings_count": {
                                    "value_count": {
                                        "field": "ratings.items.rating"
                                    }
                                },
                                "total_nps_count": {
                                    "value_count": {
                                        "field": "ratings.items.nps"
                                    }
                                },
                                "total_delivered_count": {
                                    "filter": {
                                        "term": {
                                            "status.keyword": "DELIVERED"
                                        }
                                    },
                                    "aggs": {
                                        "delivered_count": {
                                            "value_count": {
                                                "field": "id.keyword"
                                            }
                                        }
                                    }
                                },
                                "avg_response_rate": {
                                    "bucket_script": {
                                        "buckets_path": {
                                            "ratings_count": "total_ratings_count",
                                            "nps_count": "total_nps_count",
                                            "shipments_count": "total_delivered_count>delivered_count"
                                        },
                                        "script": "if (params.shipments_count > 0) { return Math.round((params.ratings_count != null && params.ratings_count > params.nps_count ? (params.ratings_count / params.shipments_count) : (params.nps_count / params.shipments_count)) * 100); } else { return 0; }"
                                    }
                                },
                                "completion_percentage": {
                                    "bucket_script": {
                                        "buckets_path": {
                                            "total": "total_shipments_count.count",
                                            "delivered": "total_delivered_count>delivered_count"
                                        },
                                        "script": "Math.round(params.delivered / params.total * 100)"
                                    }
                                },
                                "filter_non_zero_buckets": {
                                    "bucket_selector": {
                                        "buckets_path": {
                                            "total": "total_shipments_count>count"
                                        },
                                        "script": "params.total > 0"
                                    }
                                }
                            }
                        },
                        "feedback": {
                            "filter": {
                                "exists": {
                                    "field": "ratings.items.id"
                                }
                            },
                            "aggs": {
                                "feedback_hits": {
                                    "top_hits": {
                                        "size": 100,
                                        "sort": [
                                            {
                                                "ratings.items.createdAt": {
                                                    "order": "desc"
                                                }
                                            }
                                        ]
                                    }
                                }
                            }
                        }
                    },
                    query: {
                        bool: {
                            must: [
                                {
                                    range: {
                                        "expectedDeliveryTime": {
                                            "gte": moment().tz(timeZone || "America/New_York")?.startOf("day").unix(),
                                            "lte": moment().tz(timeZone || "America/New_York")?.endOf("day").unix()
                                        }
                                    }
                                }
                            ],
                            must_not: [
                                {
                                    term: {
                                        "status.keyword": "OPEN"
                                    }
                                }
                            ]
                        }
                    },
                    "size": 0
                }

            }

            if (myShipperGroup?.shipperGroup?.id) init.body.query.bool.must.push({ match: { shipperGroupId: myShipperGroup?.shipperGroup?.id } })
            if (myShipper?.shipper?.id) init.body.query.bool.must.push({ match: { shipperId: myShipper?.shipper.id } })
            else if (!myShipperGroup?.shipperGroup?.id && myShippers) {
                let shipperIdArr = []
                myShippers.forEach((item) => {
                    shipperIdArr.push(item.shipper.id)
                })
                init.body.query.bool.must.push({ "terms": { "shipperId": shipperIdArr } })
            }
            if (carrier) init.body.query.bool.must.push({ match: { "carrier.name": carrier } })

            let data = await API.post(apiName, `/search/shipment?size=${0}`, init);

            let aggregationsCopy = JSON.parse(JSON.stringify(data?.aggregations));
            setFacets(aggregationsCopy)

            let copyIntialStatus = { ...intialStatus };
            copyIntialStatus.totalDeliveries = data?.aggregations?.filtered_shipments?.total_shipments?.value;
            if (data?.aggregations?.status_breakdown?.buckets?.length) {
                data?.aggregations?.status_breakdown?.buckets?.forEach(item => {
                    if (item.key === "DELIVERED") {
                        copyIntialStatus.delivered = item?.count?.value ?? 0
                    } else if (item.key === "READY_FOR_PICKUP") {
                        copyIntialStatus.readyForPickUp = item?.count?.value ?? 0
                    } else if (item.key === "OUT_FOR_DELIVERY" || item.key === "IN_TRANSIT" || item.key === "OUT_FOR_RETURN") {
                        copyIntialStatus.outForDelivery = copyIntialStatus["outForDelivery"] + (item?.count?.value ?? 0)
                    }
                    else if (item.key === "RETURNED") {
                        copyIntialStatus.returned = item?.count?.value ?? 0
                    }
                    if (item.key === "RETURNED" || item.key === "OUT_FOR_RETURN") {
                        copyIntialStatus.missed = copyIntialStatus["missed"] + (item?.count?.value ?? 0)
                    }
                });

                const total = data?.aggregations?.filtered_shipments?.total_shipments?.value
                if (total) {
                    copyIntialStatus.deliveredPrecentage = Math.round((copyIntialStatus?.delivered / total) * 100)
                    copyIntialStatus.outForDeliveryPercentage = Math.round((copyIntialStatus?.outForDelivery / total) * 100);
                    copyIntialStatus.readyForPickUpPercentage = Math.round((copyIntialStatus?.readyForPickUp / total) * 100)
                    copyIntialStatus.returnedPercentage = Math.round((copyIntialStatus?.returned / total) * 100)
                    copyIntialStatus.totalDeliveredPercentage = Math.round(((copyIntialStatus?.delivered + copyIntialStatus?.returned) / total) * 100)
                    copyIntialStatus.totalPendingPercentage = Math.round(((copyIntialStatus?.outForDelivery + copyIntialStatus?.readyForPickUp) / total) * 100)
                }

                setStatusData(copyIntialStatus)
            }
            else setStatusData(intialStatus);

            if (data?.aggregations?.avg_nps?.value) setAvgData((prev) => ({ ...prev, avgNps: data?.aggregations?.avg_nps?.value }));
            else setAvgData((prev) => ({ ...prev, avgNps: 0 }));

            if (data?.aggregations?.avg_rating?.value) setAvgData((prev) => ({ ...prev, avgRating: data?.aggregations?.avg_rating?.value }));
            else setAvgData((prev) => ({ ...prev, avgRating: 0 }));

            if (data?.aggregations?.total_rating_count?.value) setAvgData((prev) => ({ ...prev, totalRatingCount: data?.aggregations?.total_rating_count?.value }));
            else setAvgData((prev) => ({ ...prev, totalRatingCount: 0 }));

            if (data?.aggregations?.total_nps_count?.value) setAvgData((prev) => ({ ...prev, totalNpsCount: data?.aggregations?.total_nps_count?.value }));
            else setAvgData((prev) => ({ ...prev, totalNpsCount: 0 }));

            if (data?.aggregations?.location?.buckets?.length) setLocationData(data.aggregations.location.buckets)
            else setLocationData([])

            let total_deliveries = copyIntialStatus?.delivered ?? 0
            if (data?.aggregations?.delivery_status_count?.buckets?.length && total_deliveries) {
                let onTime = 0, delayed = 0, ontimePercentage = 0, delayedPercentage = 0
                data?.aggregations?.delivery_status_count?.buckets.forEach((item) => {
                    if (item.key == "ontime") { onTime = item.count.value; ontimePercentage = Math.round((item?.count?.value / total_deliveries) * 100) }
                    if (item.key == "late") { delayed = item.count.value; delayedPercentage = Math.round((item?.count?.value / total_deliveries) * 100) }
                })
                setDeliveryStatus({ onTime: onTime, delayed: delayed, ontimePercentage: ontimePercentage, delayedPercentage: delayedPercentage });

            } else setDeliveryStatus(intialDeliveryStatus)

            if (data?.aggregations?.nps_stats) {
                let nps_stats = data?.aggregations?.nps_stats?.value
                let ratings = [
                    { label: 'Promoters', color: '#00A76F', percentage: nps_stats?.promotersPercentage, count: nps_stats?.promoters },
                    { label: 'Passives', color: '#FEC203', percentage: nps_stats?.passivesPercentage, count: nps_stats?.passives },
                    { label: 'Detractors', color: '#E84521', percentage: nps_stats?.detractorsPercentage, count: nps_stats?.detractors }
                ]
                setNpsRating(ratings)
            } else {
                setNpsRating(intialNpsRating);
            }

            if (data?.aggregations?.ratings_by_star?.buckets?.length) {
                setStarRating(data?.aggregations?.ratings_by_star?.buckets);
            }
            else { setStarRating([]); }

            if (data?.aggregations?.feedback?.doc_count) setFeedback(data?.aggregations?.feedback?.feedback_hits?.hits?.hits)
            else setFeedback([])

            showSpinner(false);
        } catch (error) {
            showSpinner(false)
            handleApiError(error)

        }
    }

    const clearFilters = () => {
        let currentTimezone = myShipperGroup?.shipperGroup?.timezone?.id || "America/New_York";
        setTimeZone(currentTimezone);
        setTz(myShipperGroup?.shipperGroup?.timezone?.alias || "EST")
        setCarrier(null);
        dispatch(storeShipper(myShippers))
        setFacets({})
    };

    function numberFormatter(number) {
        return new Intl.NumberFormat().format(number)
    }

    const tzHandle = (e) => {
        setTimeZone(e);
    };

    function formatStarRatingCount(count) {
        if (count > 1000) {
            return new Intl.NumberFormat('en-US', {
                notation: 'compact',
                maximumFractionDigits: 1
            }).format(count);
        }
        return count.toString();
    }

    const calculatePercentage = (count) => (count === 0 || avgData?.totalRatingCount === 0) ? 0 : (count / avgData?.totalRatingCount) * 100;

    return <>
        <PageHeader name={`Today (${moment().tz(timeZone || "America/New_York")?.startOf("day").format("MMMM DD, YYYY")})`}>
            <Row>
                {myShipperGroup?.shipperGroup?.id && <TableShipperGroupFilter />}
                <TableShipperFilter hideAll={myShipperGroup?.shipperGroup?.id ? true : false} />
                <ElasticFacetFilter name={'Courier'} item={carrier} facetName={'carrier'} facets={facets} onChange={setCarrier} />
                <TimeZoneFilter
                    title={""}
                    setTimeZone={setTimeZone}
                    dark={true}
                    onChange={tzHandle}
                    tz={tz}
                    setTz={setTz}
                />
                <TableClearFilter onClick={clearFilters} styleName={"mt-4"} />
                <div className="col cursor-pointer mt-1" >
                    <button type="button" className="btn btn-sm pb-0 btn-light" onClick={handleFullScreen}>
                        {fullScreen ? (
                            <>
                                <i className='fe fe-minimize-2' /> Exit Full Screen
                            </>
                        ) : (<>
                            <i className='fe fe-maximize-2' /> Full Screen
                        </>
                        )}
                    </button>
                </div>
            </Row>
        </PageHeader>
        <Container fluid>
            <div>
                <div className="progress" style={{ height: "40px" }}>
                    <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        style={{
                            width: `${statusData?.totalDeliveredPercentage}%`,
                            backgroundColor: "#00A76F", // Green color for delivered
                        }}
                    ></div>
                    <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        style={{
                            width: `${statusData?.outForDeliveryPercentage}%`,
                            backgroundColor: "#FEC203",
                        }}
                    ></div>
                    <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        style={{
                            width: `${statusData?.openPercentage}%`,
                            backgroundColor: "#EDEFF1",
                        }}
                    ></div>
                </div>
            </div>
            <Row className="mt-4">
                <StatCard title='Total Deliveries' value={statusData?.totalDeliveries ? numberFormatter(statusData?.totalDeliveries) : '-'} icon='archive' />
                <StatCard title='Delivered' spanBadgeColor={"bg-success-soft"} spanValue={statusData?.deliveredPrecentage ? `${statusData?.deliveredPrecentage}%` : false} value={statusData?.delivered ? numberFormatter(statusData?.delivered) : '-'} icon='check-circle' />
                <StatCard title='OFD/In-Transit/OFR' spanBadgeColor={"bg-warning-soft"} spanValue={statusData?.outForDeliveryPercentage ? `${statusData?.outForDeliveryPercentage}%` : false} value={statusData?.outForDelivery ? numberFormatter(statusData?.outForDelivery) : '-'} icon='truck' />
                <StatCard title='Ready For Pickup' spanBadgeColor={"bg-secondary-soft"} spanValue={statusData?.readyForPickUpPercentage ? `${statusData?.readyForPickUpPercentage}%` : false} value={statusData?.readyForPickUp ? numberFormatter(statusData?.readyForPickUp) : '-'} icon='box' />
                <StatCard title='Returned' spanBadgeColor={"bg-danger-soft"} spanValue={statusData?.returnedPercentage ? `${statusData?.returnedPercentage}%` : false} value={statusData?.returned ? numberFormatter(statusData?.returned) : 0} icon='alert-triangle' />
            </Row>
            <Row>
                <Col md={{ span: 12, order: 2 }} lg={{ span: 9, order: 1 }}>
                    <Card>
                        <Spinner display={spinner}>
                            <Row className="gy-4" md={1} lg={2} >
                                <Col className='px-5 py-3 border-end'>
                                    <div className="rating-summary">
                                        <div className="d-flex align-items-center mb-3">
                                            <div className='mb-2 me-2'>
                                                <StarRatings isAggregateRating={true} rating={avgData?.avgRating || 0} starRatedColor='#FFCD3C' starDimension='18px' starSpacing='1px' />
                                            </div>
                                            {avgData?.avgRating ? <span className="fw-bold me-2">{avgData?.avgRating.toFixed(1)}</span> : <></>}
                                            <span>Based on {formatStarRatingCount(avgData?.totalRatingCount)} Reviews</span>
                                        </div>
                                        {starRating?.map((item) => (
                                            <div key={item?.key} className="d-flex align-items-center justify-content-between mb-3">
                                                <span className="me-2">{item?.key} <span className="text-warning">&#9733;</span></span>
                                                <div className="progress flex-grow-1 me-2" style={{ height: '8px' }}>
                                                    <div
                                                        className="progress-bar bg-warning"
                                                        role="progressbar"
                                                        style={{ width: `${calculatePercentage(item?.doc_count || 0)}%` }}
                                                        aria-valuenow={calculatePercentage(item?.doc_count || 0)}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                                <span>{item?.doc_count}</span>
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                                <Col>
                                    {(npsRating[0]?.count || npsRating[1]?.count || npsRating[2]?.count) ?
                                        <Row md={1} lg={2} className='py-3 text-center'>
                                            <Col className="chart-container py-3" >
                                                <DoughnutChart
                                                    backgroundColor={["#00A76F", "#FEC203", "#E84521"]}
                                                    data={{
                                                        labels: ['Promoters', 'Passives', 'Detractors'],
                                                        datasets: [npsRating[0]?.count, npsRating[1]?.count, npsRating[2]?.count],
                                                    }}
                                                    centerText={{ text: avgData?.avgNps ?? "-", subText: 'NPS' }}
                                                    height={200}
                                                    hideLabel={true}
                                                />
                                            </Col>
                                            <Col className="nps-summary d-flex flex-column justify-content-center mt-5">
                                                {npsRating?.map((item) => (
                                                    <div key={item.label} className="d-flex align-items-center justify-content-center justify-content-lg-start mb-2">
                                                        <span
                                                            className="me-2"
                                                            style={{
                                                                width: '12px',
                                                                height: '12px',
                                                                backgroundColor: item?.color,
                                                                borderRadius: '50%',
                                                                display: 'inline-block',
                                                            }}
                                                        ></span>
                                                        <span className="me-2">{item?.label}</span>
                                                        <span className="fw-bold me-2">{item?.percentage?.toFixed(1)}%</span>
                                                        <span className="text-muted">({item?.count})</span>
                                                    </div>
                                                ))}
                                            </Col>
                                        </Row> :
                                        <div className='d-flex align-items-center justify-content-center h-100 text-muted text-sm'>No NPS data found</div>
                                    }
                                </Col>
                            </Row>
                        </Spinner>
                    </Card>
                </Col>
                <Col md={{ span: 12, order: 2 }} lg={{ span: 3, order: 2 }}>
                    <Card bodyPadding='py-0'>
                        <CardHeader className="py-1">Deliveries</CardHeader>
                        <Card.Body className="py-0">
                            <ListGroup className='list-group-flush'>
                                <ListGroupItem className="py-3 pt-4">
                                    <div className='row align-items-center'>
                                        <div className='col'>
                                            <h5 className='mb-0'>Ontime</h5>
                                        </div>
                                        <div className='col-auto'>
                                            <span >{deliveryStatus.onTime ? deliveryStatus.onTime : "-"}</span>
                                            {(deliveryStatus?.ontimePercentage && deliveryStatus.onTime) ?
                                                <div className="badge bg-success-soft mt-n1 mx-2">
                                                    {deliveryStatus.ontimePercentage}%
                                                </div>
                                                : <></>}
                                        </div>
                                    </div>
                                </ListGroupItem>
                                <ListGroupItem className="py-3 pt-4">
                                    <div className='row align-items-center'>
                                        <div className='col'>
                                            <h5 className='mb-0'>Delayed</h5>
                                        </div>
                                        <div className='col-auto'>
                                            <span >{deliveryStatus.delayed ? deliveryStatus.delayed : "-"}</span>
                                            {(deliveryStatus?.delayedPercentage && deliveryStatus.delayed) ?
                                                <div className="badge bg-warning-soft mt-n1 mx-2">
                                                    {deliveryStatus.delayedPercentage}%
                                                </div>
                                                : <></>}
                                        </div>
                                    </div>
                                </ListGroupItem>
                                <ListGroupItem className="py-3 pt-4">
                                    <div className='row align-items-center'>
                                        <div className='col'>
                                            <h5 className='mb-0'>Missed</h5>
                                        </div>
                                        <div className='col-auto'>
                                            <span>{statusData?.missed ? statusData?.missed : "-"}</span>
                                        </div>
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <div>
                <Card>
                    <CardHeader>
                        Locations
                    </CardHeader>
                    <Spinner display={spinner}>
                        <Table responsive size='sm' className='mb-0' >
                            <thead>
                                <tr>
                                    <th className='text-left'>Locations</th>
                                    <th className='text-center'>Avg Rating</th>
                                    <th className='text-center'>Nps</th>
                                    <th className='text-center' style={{ maxWidth: "80px" }}>Avg Response Rate</th>
                                    <th className='text-center'>Total Deliveries</th>
                                    <th className='text-center'>Delivered</th>
                                    <th className='text-center'>Completion</th>
                                </tr>
                            </thead>
                            <tbody>
                                {locationData?.map((item) => {
                                    return (
                                        <tr key={item?.key}>
                                            <td className='text-left' style={{ maxWidth: "160px" }}>{item.key}</td>
                                            <td className='text-center'>{item?.avg_rating?.value ? <><span className="text-warning"> &#9733; </span>{item?.avg_rating?.value.toFixed(1)}</> : "-"}</td>
                                            <td className='text-center' style={{ width: "140px" }}>{item?.avg_nps?.value ? item?.avg_nps?.value : "-"}</td>
                                            <td className='text-center' style={{ maxWidth: "140px" }}>{item?.avg_response_rate?.value ? `${item?.avg_response_rate?.value}%` : "-"}</td>
                                            <td className='text-center' style={{ width: "140px" }}>{numberFormatter(item?.total_shipments_count?.count.value)}</td>
                                            <td className='text-center' style={{ width: "140px" }}>{numberFormatter(item?.total_delivered_count?.delivered_count?.value)}</td>
                                            <td className="text-center" style={{ maxWidth: "140px" }}>
                                                <div className="row align-items-center g-0" >
                                                    <div className="col" >
                                                        <span className="me-1">
                                                            {item?.completion_percentage?.value}%
                                                        </span>
                                                    </div>
                                                    <div className="col">
                                                        <div className="progress progress-sm" >
                                                            <div
                                                                className="progress-bar"
                                                                style={{
                                                                    width: `${item?.completion_percentage?.value}%`,
                                                                    backgroundColor: "#00A76F",
                                                                }}
                                                            >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr >
                                    )
                                })
                                }
                            </tbody>
                        </Table>
                    </Spinner>
                </Card>
                <Card>
                    <CardHeader>
                        Feedback
                    </CardHeader>
                    <Spinner display={spinner}>
                        <Table responsive size='sm' className='mb-0' >
                            <thead>
                                <tr>
                                    <th className='text-start'>Shipment</th>
                                    <th className='text-start'>Reviews</th>
                                    <th className='text-center'>Star Rating</th>
                                    <th className='text-center'>NPS</th>
                                    <th className='text-center'>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    feedback?.length ? (feedback?.map((rating) => {
                                        return (
                                            (rating?._source?.ratings?.items[0]?.feedback || rating?._source?.ratings?.items[0]?.nps || rating?._source?.ratings?.items[0]?.rating) &&
                                            < tr key={rating?._source?.number}>
                                                <td style={{ wordWrap: "break-word", maxWidth: "80px" }}>
                                                    <div className="text-muted">#{rating?._source?.number}</div>
                                                    {rating?._source.shipper?.name}
                                                    <div className='small text-muted'>
                                                        {rating?._source?.shipperGroup?.name}
                                                    </div>
                                                </td>
                                                <td style={{ wordWrap: "break-word", maxWidth: "200px" }}>
                                                    {rating?._source?.ratings?.items[0]?.feedback || '-'}
                                                    <div className='text-small text-muted text-wrap'>{rating?._source?.shipTo?.name}</div>
                                                </td>
                                                <td className='text-center'>{rating?._source?.ratings?.items[0]?.rating ? <><span className="text-warning"> &#9733; </span>{rating?._source?.ratings?.items[0]?.rating}</> : "-"}</td>
                                                <td className='text-center tables-handle'>
                                                    {rating?._source?.ratings?.items[0]?.nps ? <div className={`mx-auto nps nps-${rating?._source?.ratings?.items[0]?.nps * 10}`}></div> : '-'}
                                                </td>
                                                <td className='text-center' style={{ maxWidth: "50px" }}>
                                                    {rating?._source?.ratings?.items[0]?.createdAt ?
                                                        <>
                                                            {moment(rating?._source?.ratings?.items[0]?.createdAt).tz(timeZone || "America/New_York").format("h:mm a z")}
                                                            <div className='small text-muted'>{moment(rating?._source?.ratings?.items[0]?.createdAt).tz(timeZone || "America/New_York").format("MMM D, YYYY")}</div>
                                                        </> : "-"}
                                                </td>
                                            </tr >

                                        )
                                    })) : <tr className="text-muted text-center">
                                        <td colSpan={5}>No data found</td></tr>
                                }
                            </tbody>
                        </Table>
                    </Spinner>
                </Card>
            </div>
        </Container>
    </>
}


export default TodayDashboard;